import { useState } from 'react';
import { useQueries } from 'react-query';
import { KAKAO_TOKEN_API, KAKAO_USER_INFO_API, LIFERARY_API } from './apis';

const getKakaoToken = async (data) => {
    return await KAKAO_TOKEN_API.post('/oauth/token', {
        grant_type: 'authorization_code',
        client_id: data.client_id,
        redirect_uri: data.redirect_uri,
        code: data.code
    })
}


const getKakaoUserInfo = async (accessToken) => {
    return await KAKAO_USER_INFO_API.post(
        '/v2/user/me',
        {},
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                Authorization: `Bearer ${accessToken}`
            }
        }
    )
}

const getLogginedStatus = async (data) => {
    return await LIFERARY_API.get('/user/kakao/callback', {
        params: {
            id: data.user_id,
        }
    })
}


export const useKakaoQueries = ({ client_id, redirect_uri, code }) => {
    const [accessToken, setAccessToken] = useState('')
    const [socialId, setSocialId] = useState('')

    return useQueries([
        {
            queryKey: ['getKakaoToken'],
            queryFn: async () =>
                await getKakaoToken({
                    client_id,
                    redirect_uri,
                    code
                }),
            onSuccess: (res) => {
                console.log(res.data.access_token)
                setAccessToken(res.data.access_token)
            },
            onError: async (err) => {
                alert('오류가 발생했습니다. 다시 시도해 주세요.')
                window.location.href = `${process.env.REACT_APP_LIFERARY_CLIENT_URL}/login`
            },
            retry: 0
        },
        {
            queryKey: ['getKakaoUserInfo'],
            queryFn: async () => await getKakaoUserInfo(accessToken),
            enabled: accessToken.length > 0,
            onSuccess: async (res) => {
                console.log(res.data.id.toString())
                setSocialId(res.data.id.toString())
            },
            onError: async (err) => {
                alert('오류가 발생했습니다. 다시 시도해 주세요.')
                window.location.href = `${process.env.REACT_APP_LIFERARY_CLIENT_URL}/login`
            },
            retry: 0
        },
        {
            queryKey: ['getLogginedStatus'],
            queryFn: async () => await getLogginedStatus({ user_id: socialId }),
            enabled: socialId.length > 0,
            onSuccess: async (res) => { /** res.data : 기존에 저장된 아이디라면 토큰을, 아니면 false를 리턴한다. */
                console.log(res.data)

                if (res.data === false) { // 간편 회원가입 페이지로 이동.
                    window.location.href = `${process.env.REACT_APP_LIFERARY_CLIENT_URL}/register?type=simple`
                } else {
                    window.localStorage.setItem('token', JSON.stringify(res.data))
                }
            },
            refetchOnWindowFocus: false,
        }
        //...
    ])
}