import React, { useEffect, useState } from 'react';
import Spline from '@splinetool/react-spline';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';

import { ReactComponent as TitleCircle } from './title-circle.svg';
import { ReactComponent as TitleUnderline } from './title-underline.svg';
import { ReactComponent as Sharp } from './sharp.svg';
import { ReactComponent as Ul01 } from './ul01.svg';
import { ReactComponent as Ul02 } from './ul02.svg';
import { ReactComponent as Ul03 } from './ul03.svg';
import { ReactComponent as Ul04 } from './ul04.svg';

import { ReactComponent as BgCircle01 } from './bgCircle01.svg';
import { ReactComponent as Ul0402 } from './ul04-02.svg';
import { ReactComponent as Circle02 } from './circle02.svg';
import { ReactComponent as NoIcon } from './no.svg';

import './App.css';

import Test from './pages/Test';
import { Dashboard, DashboardHome } from './pages/Dashboard'
import Feed from './pages/Feed';
import NavigationBar from './pages/NavigationBar'
import { ValueMapHome } from './pages/ValueMapHome'
import LoginScreen from './pages/Login';
import RegisterScreen from './pages/Register';
import ClippedScreen from './pages/ClippedScreen'
import { SubscriptionScreen } from './pages/SubscriptionScreen.jsx'
import RemovedScreen from './pages/RemovedScreen'
import { Settings } from './pages/Settings'


import { useNaverQueries } from './/apis/naver.js'
import { useKakaoQueries } from './/apis/kakao.js'

const convertToUserHeight = (targetY) => {
  const clientY = document.documentElement.clientHeight;
  return targetY * (clientY / 750);
}


/** height, YLength 값을 받으면 스크롤 시 애니메이션의 진행률을 리턴한다. */
const getScrollAnimRate = (height, yLength) => {
  /** 유저 컴퓨터의 화면 높이 */
  const computerHeight = document.documentElement.clientHeight;

  /** 유저의 화면 높이의 약 40% 정도를 구함. 여기서부터 글자가 보이기 시작함. */
  const criticalY = computerHeight * yLength;

  /** 글자가 모두 보이기 시작하는 높이임. 현재 글자의 높이를 저장함. */
  const anchorY = convertToUserHeight(height);

  /** 현재 글자의 위치 대비 유저 Y 스크롤의 차이 */
  const heightDiff = anchorY - window.scrollY;

  /** 글자 애니메이션의 진행 정도를 구하는 함수 */
  const getProgressRate = () => {
    if (heightDiff > criticalY) return 0;
    else if (heightDiff <= 0) return 1;
    else return 1 - heightDiff / criticalY;
  }

  let progressRate = getProgressRate();

  return progressRate;
}

const LoginLogic = (props) => {
  const navigate = useNavigate();

  let flag = false;
  useEffect(() => {
    if (!flag) {
      flag = true;
      const currentLink = window.location.href;

      // 페이지 Reloading 시 최초 1회의 로그인 검사 로직을 여기에 넣으세요

      // if (props.isLogined) {
      //   navigate('/dashboard/home')
      // } else if (!currentLink.includes("/login") && !currentLink.includes("/register")) {
      //   navigate('/')
      // }
    }
  }, [])
  return (
    <>
    </>
  )
}

const queryClient = new QueryClient()

const HandleKakaoQuery = () => {
  const AUTHORIZATION_CODE = new URL(document.location.toString()).searchParams.get('code')

  useKakaoQueries({
    client_id: process.env.REACT_APP_KAKAO_REST_API_KEY,
    redirect_uri: process.env.REACT_APP_KAKAO_REDIRECT_URI,
    code: AUTHORIZATION_CODE
  })
}

const HandleNaverQuery = () => {
  const NAVER_URL = new URL(document.location.toString())
  const AUTHORIZATION_CODE = NAVER_URL.searchParams.get('code')
  const AUTHORIZATION_STATE = NAVER_URL.searchParams.get('state')

  if (NAVER_URL.searchParams.get('error')) {
    alert('오류가 발생했습니다. 다시 시도해 주세요.')
    window.location.href = `${process.env.REACT_APP_LIFERARY_CLIENT_URL}/login`
  }

  useNaverQueries({
    code: AUTHORIZATION_CODE,
    state: AUTHORIZATION_STATE,
  })
}

function App() {
  const [isLogined, setIsLogined] = useState(true);

  return (
    <QueryClientProvider client={queryClient}>


      <div className="App">
        <BrowserRouter>
          <LoginLogic isLogined={isLogined} setIsLogined={setIsLogined} />
          <NavigationBar isLogined={isLogined} />
          <div id="screen">
            <Routes>
              <Route exact path="/" element={<Main />}></Route>
              <Route exact path="/login" element={<LoginScreen />}>

              </Route>
              <Route exact path="/register" element={<RegisterScreen setIsLogined={setIsLogined} />}>

              </Route>
              <Route exact path="/test" element={<Test />}></Route>
              <Route exact path="/dashboard" element={<Dashboard setIsLogined={setIsLogined} />}>
                <Route path="home" element={<DashboardHome />} />
                <Route path="map" element={<ValueMapHome />} />
                <Route path="feed" element={<Feed />} />
                <Route path="clip" element={<ClippedScreen />} />

                <Route path="settings" element={<Settings />} />
                <Route path="subscription" element={<SubscriptionScreen />} />
                <Route path="removed" element={<RemovedScreen />} />
              </Route>

              <Route exact path="/oauth">
                <Route path="kakao" element={<HandleKakaoQuery />} />
                <Route path="naver" element={<HandleNaverQuery />} />
              </Route>
            </Routes>
          </div>

        </BrowserRouter>
      </div>
    </QueryClientProvider>
  )
}



const Main = () => {
  const [contentStart, setContentStart] = useState(0);
  useEffect(() => {

    const modifyCubeSize = () => {
      const bgCube = document.getElementById('background-cube');
      const bgCube2 = document.getElementById('desc-cube');

      const scaleRatio = document.body.clientWidth / window.screen.width;
      if (bgCube && bgCube2) {
        bgCube.style.transform = `scale(${scaleRatio})`
        bgCube2.style.transform = `scale(${scaleRatio * 0.9})`
      }
    }

    window.onresize = modifyCubeSize;
    /** 
     * 내 컴퓨터에서 높이는 750, 사용자 컴퓨터의 높이에 비례하도록 변환한다.
     */

    window.addEventListener('scroll', () => {
      /** 스크린의 전체 높이 */
      const scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );

      /** 유저 컴퓨터의 화면 높이 */
      const computerHeight = document.documentElement.clientHeight;

      /** 전체 화면 대비 몇 퍼센트만큼 스크롤했는지 저장 */
      const scrollPercentage = window.scrollY / (scrollHeight - computerHeight) * 100;

      // Progress Bar Control.
      const progressBar = document.getElementById('progress-bar');
      if (progressBar) {
        progressBar.style.width = scrollPercentage + 'vw';
      }


      // 2번째 페이지로 넘어갈 시, 컨텐츠가 시작됨.
      setContentStart(getScrollAnimRate(862.5, 0));
    })
  }, [])

  return (
    <div id="landing-page">
      <div id="progress-bar"></div>
      <div className="main-page">
        <BackgroundCircle gradient="radial-gradient(50% 50% at 50% 50%, rgba(160, 240, 197, 0.2) 0%, rgba(117, 241, 226, 0.04) 100%)"
          radius="28vw" top="17vw" left="28vw" blur="2" />
        <BackgroundCircle gradient="radial-gradient(50% 50% at 50% 50%, rgba(160, 240, 197, 0.1) 0%, rgba(117, 241, 226, 0.02) 100%)"
          radius="30vw" top="5vw" left="52vw" blur="2" />
        <div id="background-cube" className="noselect">
          <Spline
            scene="https://prod.spline.design/gBbujvbezVeL7TQs/scene.splinecode"
          />
        </div>



        <div className="main-title noselect">
          <div className="black-bg-font"></div>

          <TitleCircle width="13.9vw" height="10vw" />
          <TitleUnderline className="main-title-underline" width="24vw" height="1.8vw" />
          <h1>우리는</h1>
          <div id="tag-slide">
            <div className="tag-text title-gradient">#생각</div>
          </div>
          <h1>합니다. 결국 </h1>
          <span id="main-title-human" className="title-gradient">인간이기에</span>
        </div>
        <button id="main-start-button">시작하기</button>
        <div className="down-arrow" onClick={() => {
          window.scrollBy({
            top: window.innerHeight,
            left: 0,
            behavior: 'smooth',
          });
        }}>
          <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.5 28L28.5 42L14.5 28" stroke="#949DAD" strokeWidth="2.33333" strokeLinecap="round" />
            <path d="M42.5 14L28.5 28L14.5 14" stroke="#949DAD" strokeWidth="2.33333" strokeLinecap="round" />
          </svg>

        </div>
      </div>
      <div className="sub-page-container">
        <div className="sub-page" style={contentStart === 1 ? { position: 'fixed', left: '0', top: '0', } : { position: 'relative' }}>
          <div className="sub-page-background">
            <SvgFade height={1000} length={500} svg={BgCircle01} opacity={'true'} />
          </div>

          <ScrollPage height={1600}
            element={Page01} />

          <ScrollPage height={2700}
            element={Page02} />

          <ScrollPage height={3800}
            element={Page03} />

          <ScrollPage height={4900}
            element={Page04} />

          <ScrollPage height={6000}
            element={Page05} />
        </div>
      </div>

      <div className="desc-page">
        <div className="service-desc-content">
          <h1>점</h1>
          <p>나의 자아를 형성하는 다양한 가치들을 골라</p>
          <p>지도를 완성해 보세요.</p>
        </div>
      </div>

      <div className="desc-page">
        <div className="service-desc-content">
          <h1>선</h1>
          <p>내 삶을 지탱하는 목표를</p>
          <p>문장으로 나타내보세요.</p>
        </div>
      </div>

      <div className="desc-page">
        <div className="service-desc-content">
          <h1>면</h1>
          <p>삶의 가치들에 대한 생각과 경험을</p>
          <p>글로 적어보세요.</p>
        </div>
      </div>
    </div>
  )
}

const BackgroundCircle = (props) => {
  const style = {
    background: props.gradient,
    // boxShadow: '0 0 10px 10px rgb(24, 24, 27) inset',
    filter: `blur(${props.blur}vw)`,
    borderRadius: '50%',
    width: props.radius,
    height: props.radius,
    position: 'absolute',
    top: props.top,
    left: props.left,
  }

  return (
    <div className="background-circle" style={style} />
  )
}

const ScrollPage = (props) => {
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  let flag = false;
  useEffect(() => {
    if (!flag) {
      flag = true;

      window.addEventListener('scroll', () => {
        setStartTime(getScrollAnimRate(props.height - 700, 0));
        setEndTime(getScrollAnimRate(props.height + 300, 0.2));
      })
    }
  }, [])

  return (
    <div className="sub-page-content noselect" style={{ opacity: (1 - endTime), display: startTime === 1 && endTime !== 1 ? 'block' : 'none' }}>
      <props.element />
    </div>
  )
}

const GradientText = (props) => {
  const [textVisibility, setTextVisibility] = useState(new Array(props.text.length).fill(0));

  let flag = false;
  useEffect(() => {
    if (!flag) {
      flag = true;

      let progressRate = 0;

      window.addEventListener('scroll', () => {
        progressRate = getScrollAnimRate(props.height, 0.4)
        let newTextVisiblity = new Array(props.text.length).fill(0);

        for (let i = 0; i < Math.floor(progressRate * props.text.length); i++) {
          newTextVisiblity[i] = 1;
        }
        setTextVisibility(newTextVisiblity);
      })
    }
  }, [])

  return (
    <div className="gradient-text" id={props.id ? props.id : null}>
      {
        textVisibility.map((v, i) => {
          return (<span className={v === 1 ? "gradient-fade text-fadein" : "gradient-fade text-fadeout"}>{props.text[i]}</span>)
        })
      }
    </div>
  )
}

const SvgFade = (props) => {
  let flag = false;
  const [progressRate, setProgressRate] = useState(0);
  useEffect(() => {
    flag = true;

    window.addEventListener('scroll', () => {
      setProgressRate(getScrollAnimRate(props.height, 0.18));
    })
  }, [])

  return (
    props.svg ? <props.svg id={props.id}
      strokeDasharray={props.length} strokeDashoffset={`${props.length - props.length * progressRate}`}
      style={{ opacity: props.opacity === "true" ? progressRate : 1 }} />
      : null
  )
}

const ColorGradientText = (props) => {
  let flag = false;
  const [progressRate, setProgressRate] = useState(0);
  useEffect(() => {
    flag = true;

    window.addEventListener('scroll', () => {
      setProgressRate(getScrollAnimRate(props.height, 0.2));
    })
  }, [])

  return (
    <span id={props.id} className='title-gradient' style={{ opacity: progressRate, position: 'absolute' }}>{props.text}</span>
  )
}

const List01 = (props) => {
  const [progressRate, setProgressRate] = useState(0);


  let flag = false;
  useEffect(() => {
    flag = true;

    window.addEventListener('scroll', () => {
      setProgressRate(getScrollAnimRate(props.height, 0.25));
    })
  }, [])

  return (
    <div id="list-page01" style={{ height: (progressRate * 12) + 'svw', opacity: progressRate }}>
      <p className="list-item" >답답함</p>
      <p className="list-item" >공허함</p>
      <p className="list-item" >무기력함</p>
    </div>
  )
}

const DescCube = (props) => {
  const [progressRate, setProgressRate] = useState(0);
  const [pageRates, setPageRates] = useState([0, 0, 0]);
  const [left, setLeft] = useState(10);

  let style = {
    position: 'absolute',
    top: '20vh',
  }

  Object.defineProperty(style, 'left', {
    writable: true
  });

  let flag = false;
  useEffect(() => {
    if (!flag) {
      flag = true;

      window.addEventListener('scroll', () => {
        setProgressRate(getScrollAnimRate(props.height, 0));

        let newPageRates = [];
        let curPage = 0;
        for (let i = 1; i <= 3; i++) {
          const pr = getScrollAnimRate(props.height + i * 770, 0.5);
          newPageRates.push(pr);

          if (pr !== 0) curPage++;
        }
        setPageRates(newPageRates)

        if (curPage === 1) {
          setLeft(10 - newPageRates[0] * 18);
        }

        else if (curPage === 2) {
          setLeft(-8 + newPageRates[1] * 40);
        }

        else if (curPage === 3) {
          setLeft(32 - newPageRates[2] * 40);
        }
      })
    }
  }, [])

  return (
    <>
      <div id="cube-space" style={progressRate === 1 ? { display: 'block' } : { display: 'none' }} />
      <div id="desc-cube" style={progressRate === 1 ? { ...style, left: left + 'vw', } : null}>
        <Spline
          scene="https://prod.spline.design/gBbujvbezVeL7TQs/scene.splinecode"
        />
      </div>
    </>
  )
}


const Page01 = () => {
  return (
    <>
      <div className="sub-page-text">
        <SvgFade height={1550} length={40} id={'sharp'}
          svg={Sharp} />
        <SvgFade height={1400} length={450} id={'underline-page01'}
          svg={Ul01} />
        <GradientText text="무의미한 콘텐츠를 소비하며     허무함 을 느껴본 적 있나요?" height={1200} />
        <ColorGradientText id="page1-gradient" text="허무함" height={1650} />
      </div>

      <List01 height={1650} />
    </>
  )
}

const Page02 = () => {
  return (
    <div className="sub-page-text">
      <SvgFade height={2450} length={1040} id={'circle-page02'}
        svg={Circle02} />
      <SvgFade height={2660} length={410} id={'underline-page02'}
        svg={Ul02} />
      <GradientText text="삶에 있어 온전한 '나'의 결정은 언제가 마지막이었나요?" height={2280} />
      <ColorGradientText id="page2-gradient" text="온전한 '나'의 결정" height={2450} />
    </div>
  )
}

const Page03 = () => {
  return (
    <div className="sub-page-text">
      <SvgFade height={3850} length={40} id={'noicon-page03'} opacity={"true"}
        svg={NoIcon} />
      <SvgFade height={3540} length={410} id={'underline-page03'}
        svg={Ul03} />

      <GradientText text={`주체성을 잃은 삶에서 오는 자괴감은`} height={3360} />
      <GradientText text={`온전히 개인이 짊어져야 할 무게가 아닙니다`} height={3360} />

      <ColorGradientText id="page3-gradient1" text="주체성을 잃은 삶" height={3540} />
      <ColorGradientText id="page3-gradient2" text="아닙니다" height={3780} />
    </div>
  )
}

const Page04 = () => {
  return (
    <div className="sub-page-text">
      <SvgFade height={4750} length={1100} id={'underline-page04'}
        svg={Ul04} />
      <SvgFade height={4750} length={1000} id={'underline02-page04'}
        svg={Ul0402} />

      <GradientText text={`자아탐색 글쓰기 플랫폼, 라이프러리는`} height={4420} />
      <GradientText text={`우리 사회의 괴리에 맞섭니다`} id="page4-title" height={4450} />

      <ColorGradientText id="page4-gradient1" text="우리 사회의 괴리에 맞섭니다" height={4800} />
    </div>
  )
}

const Page05 = () => {
  return (
    <div className="desc-content noselect">
      <GradientText text={'몰입을 방해하는 것들이 팽배한 세상 속,'} height={5400} />
      <GradientText text={'라이프러리는 제공합니다.'} height={5500} />
      <DescCube height={5600} />
      <GradientText text={'온전한 사고의 장 BOX'} height={5700} />
    </div>
  )
}



export default App;
