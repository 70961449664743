import React, { useState, useEffect } from 'react';
import { ReactComponent as ReadMoreIcon } from '../icons/readmore.svg';
import { ReactComponent as BookmarkIcon } from '../icons/clip.svg';
import { ReactComponent as BookmarkFilledIcon } from '../icons/clip-filled.svg';
import { ReactComponent as HeartFilledIcon } from '../icons/heart-filled.svg';
import { ReactComponent as HeartIcon } from '../icons/heart.svg';
import { ReactComponent as IdeaIcon } from '../icons/idea-bulb.svg';
import { ReactComponent as ShineIcon } from '../icons/feed-shine.svg';
import { ReactComponent as InspireIcon } from '../icons/feed-inspire.svg';
import { ReactComponent as RightArrowIcon } from '../icons/right-arrow.svg';

import SampleBackground from '../sample_bg.jpg';


const ANIMATION_TIME = 400;
const FEED_BANNER_MOVE_INTERVAL = 6000;
const BANNER_WRITING_COUNT = 7;
const FADE_DIRECTION = {
    left: false,
    right: true,
};
Object.freeze(FADE_DIRECTION);

const FeedTodayMenu = (props) => {
    const handleClick = () => {
        if (!props.cooltime) {
            props.setCooltime(true);
            props.setFeedFadeDirection(props.index > props.feedToday ? FADE_DIRECTION.right : FADE_DIRECTION.left);
            props.setFeedToday(props.index);

            setTimeout(() => {
                props.setCooltime(false);
            }, ANIMATION_TIME)
        }

    }

    return (
        <div className="feed-today-menu-button" style={{ background: props.isSelected ? 'rgba(228, 228, 231, 1)' : 'rgba(63, 63, 70, 1)' }} onClick={() => { handleClick() }}></div>
    )
}

const TopFeedLine = (props) => {
    const OUTLINE_COLORS = ['rgba(235, 194, 214, 0.6)', 'rgba(174, 219, 209, 0.6)', 'rgba(219, 203, 251, 0.6)', 'rgba(185, 212, 239, 0.6)',]
    const [scrollOpacity, setScrollOpacity] = useState({
        left: 0,
        right: 1,
    })

    const horizontalScrollHandler = (e) => {
        const maxScrollWidth = e.target.scrollWidth - e.target.clientWidth;
        const currentScrollWidth = e.target.scrollLeft;

        setScrollOpacity({
            left: currentScrollWidth / maxScrollWidth,
            right: 1 - currentScrollWidth / maxScrollWidth,
        })
    }

    return (
        <div className="feed-topic-container">
            <h3>이번 주 가장 빛난 글 7선</h3>
            <div className="feed-topic-content feed-top-topic-content noselect" onScroll={(e) => horizontalScrollHandler(e)}>
                <div className="feed-topic-writings" style={{ width: props.writings.length * 32 + 'vmin' }} >
                    {
                        [...props.writings].map((v, i) => {
                            return (
                                <div className="feed-topic-writing">
                                    <div className="feed-topic-writing-top-shine">
                                        <ShineIcon fill={i === 0 ? 'rgba(110, 247, 222, 1)' : "rgba(193, 250, 240, 1)"} />
                                        <p style={{ color: i === 0 ? 'rgba(31, 31, 31, 1)' : "rgba(72, 72, 72, 1)" }}>{i + 1}</p>
                                    </div>
                                    <IdeaIcon stroke="rgba(228, 228, 231, 1)" />
                                    <p className="feed-topic-writing-description">{v.detail}</p>

                                    <div className="feed-topic-writing-lower">
                                        <p>{`역시 남쪽으로 좀더 아래로 내려가서, 별들의 횃불인 장 드 밀랑이 반짝이고 있습니다. 누구나 이런 밤의 세계에 익숙하지 못한 사람은 좀 무서워질 것입니다만. 역시 남쪽으로 좀더 아래로 내려가서, 별들의 횃불인 장 드 밀랑이 반짝이고 있습니다. 그때까지 그렇게 가까이 아가씨를 바라본 적이 없었다는 것도 사실입니다.”`}</p>
                                        <div className="feed-topic-writing-topline">
                                            <div className="feed-topic-writing-liked">
                                                {/* <span onClick={() => {
                                                let copyWritings = props.writings;
                                                let copyIndex = copyWritings.indexOf(v);
                                                copyWritings[copyIndex].isClipped = !copyWritings[copyIndex].isClipped;
                                                props.setWritings([...copyWritings]);
                                            }}>
                                                {
                                                    v.isClipped ? <BookmarkFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <BookmarkIcon stroke="rgba(228, 228, 231, 1)" />
                                                }
                                            </span> */}
                                                <span onClick={() => {
                                                    let copyWritings = props.writings;
                                                    let copyIndex = copyWritings.indexOf(v);
                                                    copyWritings[copyIndex].isLiked = !copyWritings[copyIndex].isLiked;
                                                    copyWritings[copyIndex].isLiked ? copyWritings[copyIndex].liked++ : copyWritings[copyIndex].liked--;

                                                    props.setWritings([...copyWritings]);
                                                }}>
                                                    {
                                                        v.isLiked ? <HeartFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <HeartIcon stroke="rgba(228, 228, 231, 1)" />
                                                    }
                                                </span>
                                                <p>{v.liked}</p>
                                            </div>
                                            <p>{v.nickname}</p>
                                        </div>

                                    </div>

                                    <div className="feed-topic-writing-upper">
                                        <p className="feed-topic-writing-date">{v.date}</p>
                                        <div className="feed-topic-writing-title">
                                            <h3>{v.title}</h3>
                                        </div>
                                        <div className="feed-topic-writing-tags">
                                            {
                                                [...v.tags].map(v => {
                                                    return (
                                                        <div className="feed-topic-writing-tag" style={{ background: OUTLINE_COLORS[(i + 1) % 3] }}>{`#${v}`}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            <span className="feed-topic-leftscroll" style={{ opacity: scrollOpacity.left, display: scrollOpacity.left < 0.01 ? 'none' : 'inline' }} />
            <span className="feed-topic-rightscroll" style={{ opacity: scrollOpacity.right, display: scrollOpacity.right < 0.01 ? 'none' : 'inline' }} />
        </div >
    )
}

const TopInspireLine = (props) => {
    const OUTLINE_COLORS = ['rgba(235, 194, 214, 0.6)', 'rgba(174, 219, 209, 0.6)', 'rgba(219, 203, 251, 0.6)', 'rgba(185, 212, 239, 0.6)',]
    const [scrollOpacity, setScrollOpacity] = useState({
        left: 0,
        right: 1,
    })

    const [topWritings, setTopWritings] = useState(props.writings.slice(0, 3))

    return (
        <div className="feed-topic-container">
            <h3>이번 주 가장 많은 영감을 준 글</h3>
            <div className="feed-topic-content feed-top-topic-content feed-top-inspire-content noselect">
                <div className="feed-topic-writings" style={{ width: '100%' }} >
                    { // 2위
                        <div className="feed-topic-writing">
                            <div className="feed-topic-writing-top-shine">
                                <InspireIcon fill={'rgba(211, 211, 211, 1)'} />
                                <p style={{ color: 'rgba(31, 31, 31, 1)' }}>2</p>
                            </div>
                            <IdeaIcon stroke="rgba(228, 228, 231, 1)" />
                            <p className="feed-topic-writing-description">{topWritings[1].detail}</p>

                            <div className="feed-topic-writing-lower">
                                <p>{`역시 남쪽으로 좀더 아래로 내려가서, 별들의 횃불인 장 드 밀랑이 반짝이고 있습니다. 누구나 이런 밤의 세계에 익숙하지 못한 사람은 좀 무서워질 것입니다만. 역시 남쪽으로 좀더 아래로 내려가서, 별들의 횃불인 장 드 밀랑이 반짝이고 있습니다. 그때까지 그렇게 가까이 아가씨를 바라본 적이 없었다는 것도 사실입니다.”`}</p>
                                <div className="feed-topic-writing-topline">
                                    <div className="feed-topic-writing-liked">
                                        {/* <span onClick={() => {
                                                let copyWritings = props.writings;
                                                let copyIndex = copyWritings.indexOf(topWritings[1]);
                                                copyWritings[copyIndex].isClipped = !copyWritings[copyIndex].isClipped;
                                                props.setWritings([...copyWritings]);
                                            }}>
                                                {
                                                    topWritings[1].isClipped ? <BookmarkFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <BookmarkIcon stroke="rgba(228, 228, 231, 1)" />
                                                }
                                            </span> */}
                                        <span onClick={() => {
                                            let copyWritings = props.writings;
                                            let copyIndex = copyWritings.indexOf(topWritings[1]);
                                            copyWritings[copyIndex].isLiked = !copyWritings[copyIndex].isLiked;
                                            copyWritings[copyIndex].isLiked ? copyWritings[copyIndex].liked++ : copyWritings[copyIndex].liked--;

                                            props.setWritings([...copyWritings]);
                                        }}>
                                            {
                                                topWritings[1].isLiked ? <HeartFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <HeartIcon stroke="rgba(228, 228, 231, 1)" />
                                            }
                                        </span>
                                        <p>{topWritings[1].liked}</p>
                                    </div>
                                    <p>{topWritings[1].nickname}</p>
                                </div>
                            </div>

                            <div className="feed-topic-writing-upper">
                                <p className="feed-topic-writing-date">{topWritings[1].date}</p>
                                <div className="feed-topic-writing-title">
                                    <h3>{topWritings[1].title}</h3>
                                </div>
                                <div className="feed-topic-writing-tags">
                                    {
                                        [...topWritings[1].tags].map((v, i) => {
                                            return (
                                                <div className="feed-topic-writing-tag" style={{ background: OUTLINE_COLORS[(i + 1) % 3] }}>{`#${v}`}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    { // 1위
                        <div className="feed-topic-writing">
                            <div className="feed-topic-writing-top-shine">
                                <InspireIcon fill={'rgba(255, 255, 255, 1)'} />
                                <p style={{ color: 'rgba(31, 31, 31, 1)' }}>1</p>
                            </div>
                            <IdeaIcon stroke="rgba(228, 228, 231, 1)" />
                            <p className="feed-topic-writing-description">{topWritings[0].detail}</p>

                            <div className="feed-topic-writing-lower">
                                <p>{`역시 남쪽으로 좀더 아래로 내려가서, 별들의 횃불인 장 드 밀랑이 반짝이고 있습니다. 누구나 이런 밤의 세계에 익숙하지 못한 사람은 좀 무서워질 것입니다만. 역시 남쪽으로 좀더 아래로 내려가서, 별들의 횃불인 장 드 밀랑이 반짝이고 있습니다. 그때까지 그렇게 가까이 아가씨를 바라본 적이 없었다는 것도 사실입니다.”`}</p>
                                <div className="feed-topic-writing-topline">
                                    <div className="feed-topic-writing-liked">
                                        {/* <span onClick={() => {
                                                let copyWritings = props.writings;
                                                let copyIndex = copyWritings.indexOf(topWritings[1]);
                                                copyWritings[copyIndex].isClipped = !copyWritings[copyIndex].isClipped;
                                                props.setWritings([...copyWritings]);
                                            }}>
                                                {
                                                    topWritings[1].isClipped ? <BookmarkFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <BookmarkIcon stroke="rgba(228, 228, 231, 1)" />
                                                }
                                            </span> */}
                                        <span onClick={() => {
                                            let copyWritings = props.writings;
                                            let copyIndex = copyWritings.indexOf(topWritings[0]);
                                            copyWritings[copyIndex].isLiked = !copyWritings[copyIndex].isLiked;
                                            copyWritings[copyIndex].isLiked ? copyWritings[copyIndex].liked++ : copyWritings[copyIndex].liked--;

                                            props.setWritings([...copyWritings]);
                                        }}>
                                            {
                                                topWritings[0].isLiked ? <HeartFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <HeartIcon stroke="rgba(228, 228, 231, 1)" />
                                            }
                                        </span>
                                        <p>{topWritings[0].liked}</p>
                                    </div>
                                    <p>{topWritings[0].nickname}</p>
                                </div>
                            </div>

                            <div className="feed-topic-writing-upper">
                                <p className="feed-topic-writing-date">{topWritings[1].date}</p>
                                <div className="feed-topic-writing-title">
                                    <h3>{topWritings[0].title}</h3>
                                </div>
                                <div className="feed-topic-writing-tags">
                                    {
                                        [...topWritings[0].tags].map((v, i) => {
                                            return (
                                                <div className="feed-topic-writing-tag" style={{ background: OUTLINE_COLORS[(i + 1) % 3] }}>{`#${v}`}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    { // 3위
                        <div className="feed-topic-writing">
                            <div className="feed-topic-writing-top-shine">
                                <InspireIcon fill={'rgba(211, 211, 211, 1)'} />
                                <p style={{ color: 'rgba(31, 31, 31, 1)' }}>3</p>
                            </div>
                            <IdeaIcon stroke="rgba(228, 228, 231, 1)" />
                            <p className="feed-topic-writing-description">{topWritings[2].detail}</p>

                            <div className="feed-topic-writing-lower">
                                <p>{`역시 남쪽으로 좀더 아래로 내려가서, 별들의 횃불인 장 드 밀랑이 반짝이고 있습니다. 누구나 이런 밤의 세계에 익숙하지 못한 사람은 좀 무서워질 것입니다만. 역시 남쪽으로 좀더 아래로 내려가서, 별들의 횃불인 장 드 밀랑이 반짝이고 있습니다. 그때까지 그렇게 가까이 아가씨를 바라본 적이 없었다는 것도 사실입니다.”`}</p>
                                <div className="feed-topic-writing-topline">
                                    <div className="feed-topic-writing-liked">
                                        {/* <span onClick={() => {
                                                let copyWritings = props.writings;
                                                let copyIndex = copyWritings.indexOf(topWritings[1]);
                                                copyWritings[copyIndex].isClipped = !copyWritings[copyIndex].isClipped;
                                                props.setWritings([...copyWritings]);
                                            }}>
                                                {
                                                    topWritings[1].isClipped ? <BookmarkFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <BookmarkIcon stroke="rgba(228, 228, 231, 1)" />
                                                }
                                            </span> */}
                                        <span onClick={() => {
                                            let copyWritings = props.writings;
                                            let copyIndex = copyWritings.indexOf(topWritings[2]);
                                            copyWritings[copyIndex].isLiked = !copyWritings[copyIndex].isLiked;
                                            copyWritings[copyIndex].isLiked ? copyWritings[copyIndex].liked++ : copyWritings[copyIndex].liked--;

                                            props.setWritings([...copyWritings]);
                                        }}>
                                            {
                                                topWritings[2].isLiked ? <HeartFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <HeartIcon stroke="rgba(228, 228, 231, 1)" />
                                            }
                                        </span>
                                        <p>{topWritings[2].liked}</p>
                                    </div>
                                    <p>{topWritings[2].nickname}</p>
                                </div>
                            </div>

                            <div className="feed-topic-writing-upper">
                                <p className="feed-topic-writing-date">{topWritings[2].date}</p>
                                <div className="feed-topic-writing-title">
                                    <h3>{topWritings[2].title}</h3>
                                </div>
                                <div className="feed-topic-writing-tags">
                                    {
                                        [...topWritings[2].tags].map((v, i) => {
                                            return (
                                                <div className="feed-topic-writing-tag" style={{ background: OUTLINE_COLORS[(i + 1) % 3] }}>{`#${v}`}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >
        </div >
    )
}


const FeedLine = (props) => {
    const OUTLINE_COLORS = ['rgba(235, 194, 214, 0.6)', 'rgba(174, 219, 209, 0.6)', 'rgba(219, 203, 251, 0.6)', 'rgba(185, 212, 239, 0.6)',]
    const [scrollOpacity, setScrollOpacity] = useState({
        left: 0,
        right: 1,
    })

    const horizontalScrollHandler = (e) => {
        const maxScrollWidth = e.target.scrollWidth - e.target.clientWidth;
        const currentScrollWidth = e.target.scrollLeft;

        setScrollOpacity({
            left: currentScrollWidth / maxScrollWidth,
            right: 1 - currentScrollWidth / maxScrollWidth,
        })
    }

    return (
        <div className="feed-topic-container">
            <h3>{props.topic}</h3>
            <div className="feed-topic-content noselect" onScroll={(e) => horizontalScrollHandler(e)}>
                <div className="feed-topic-writings" style={{ width: props.writings.length * 31 + 'vmin' }} >
                    {
                        [...props.writings].map((v, i) => {
                            return (
                                <div className="feed-topic-writing">
                                    <IdeaIcon stroke="rgba(228, 228, 231, 1)" />
                                    <p className="feed-topic-writing-description">{v.detail}</p>

                                    <div className="feed-topic-writing-lower">
                                        <div className="feed-topic-writing-liked">
                                            {/* <span onClick={() => {
                                                let copyWritings = props.writings;
                                                let copyIndex = copyWritings.indexOf(v);
                                                copyWritings[copyIndex].isClipped = !copyWritings[copyIndex].isClipped;
                                                props.setWritings([...copyWritings]);
                                            }}>
                                                {
                                                    v.isClipped ? <BookmarkFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <BookmarkIcon stroke="rgba(228, 228, 231, 1)" />
                                                }
                                            </span> */}
                                            <span onClick={() => {
                                                let copyWritings = props.writings;
                                                let copyIndex = copyWritings.indexOf(v);
                                                copyWritings[copyIndex].isLiked = !copyWritings[copyIndex].isLiked;
                                                copyWritings[copyIndex].isLiked ? copyWritings[copyIndex].liked++ : copyWritings[copyIndex].liked--;

                                                props.setWritings([...copyWritings]);
                                            }}>
                                                {
                                                    v.isLiked ? <HeartFilledIcon fill="rgba(228, 228, 231, 1)" stroke="rgba(228, 228, 231, 1)" /> : <HeartIcon stroke="rgba(228, 228, 231, 1)" />
                                                }
                                            </span>
                                            <p>{v.liked}</p>
                                        </div>
                                        <p>{v.nickname}</p>
                                    </div>

                                    <div className="feed-topic-writing-upper">
                                        <p className="feed-topic-writing-date">{v.date}</p>
                                        <div className="feed-topic-writing-title">
                                            <h3>{v.title}</h3>
                                        </div>
                                        <div className="feed-topic-writing-tags">
                                            {
                                                [...v.tags].map(v => {
                                                    return (
                                                        <div className="feed-topic-writing-tag" style={{ background: OUTLINE_COLORS[(i + 1) % 3] }}>{`#${v}`}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            <span className="feed-topic-leftscroll" style={{ opacity: scrollOpacity.left, display: scrollOpacity.left < 0.001 ? 'none' : 'inline' }} />
            <span className="feed-topic-rightscroll" style={{ opacity: scrollOpacity.right, display: scrollOpacity.right < 0.001 ? 'none' : 'inline' }} />
        </div >
    )
}

const TemporarySaveLine = (props) => {

    return (
        <div className="feed-temporary-save-content">
            <h3>{`"${props.writing.title}" 글이 기다리고 있어요!`}</h3>
            <div className="feed-temporary-save-continue">
                <p>임시저장 글 마저 쓰러가기</p>
                <RightArrowIcon fill="rgba(244, 244, 245, 1)" />
            </div>
        </div>
    )
}

const Feed = () => {
    const [feedToday, setFeedToday] = useState(1);
    const [feedFadeDirection, setFeedFadeDirection] = useState(FADE_DIRECTION.right);
    const [cooltime, setCooltime] = useState(false);
    const [writings, setWritings] = useState([
        {
            date: '2024.06.07',
            title: '병아리장은 남들보다 일찍 서둘러',
            detail: '일찍 일어난 병아리는 얼마나 많은 벌레를 잡아먹을까?',
            tags: ['태그', '태그'],
            nickname: '작가명',
            liked: 112,
            isClipped: false,
            isLiked: false,
        },
        {
            date: '2024.06.07',
            title: '혼자서 철도 없이 난 너만 그린다.',
            detail: '일찍 일어난 병아리는 얼마나 많은 벌레를 잡아먹을까?',
            tags: ['태그', '태그', '태그',],
            nickname: '작가명',
            liked: 112,
            isClipped: false,
            isLiked: false,
        },
        {
            date: '2024.06.07',
            title: '가슴 속에 하나 둘 새겨지는 별을 이제 다 못헤는 것은 쉬이 아침에 떠 있는 해의 색 내 맘대로 색칠해',
            detail: '일찍 일어난 병아리는 얼마나 많은 벌레를 잡아먹을까?',
            tags: ['태그',],
            nickname: '작가명',
            liked: 112,
            isClipped: false,
            isLiked: false,
        },
        {
            date: '2024.06.07',
            title: '뤼블롱 산의 양치기',
            detail: '일찍 일어난 병아리는 얼마나 많은 벌레를 잡아먹을까?',
            tags: ['태그', '태그'],
            nickname: '작가명',
            liked: 112,
            isClipped: false,
            isLiked: false,
        },
        {
            date: '2024.06.07',
            title: '병아리장은 남들보다 일찍 서둘러',
            detail: '일찍 일어난 병아리는 얼마나 많은 벌레를 잡아먹을까?',
            tags: ['태그',],
            nickname: '작가명',
            liked: 112,
            isClipped: false,
            isLiked: false,
        },
        {
            date: '2024.06.07',
            title: '혼자서 철도 없이 난 너만 그린다.',
            detail: '일찍 일어난 병아리는 얼마나 많은 벌레를 잡아먹을까?',
            tags: ['태그', '태그', '태그',],
            nickname: '작가명',
            liked: 112,
            isClipped: false,
            isLiked: false,
        },
        {
            date: '2024.06.07',
            title: '가슴 속에 하나 둘 새겨지는 별을 이제 다 못헤는 것은 쉬이 아침에 떠 있는 해의 색 내 맘대로 색칠해',
            detail: '일찍 일어난 병아리는 얼마나 많은 벌레를 잡아먹을까?',
            tags: ['태그',],
            nickname: '작가명',
            liked: 112,
            isClipped: false,
            isLiked: false,
        },
    ])

    useEffect(() => {
        const container = document.getElementById('feed-today')
        const currentDiv = document.getElementById('feed-today-content-main');

        if (currentDiv) {
            if (feedFadeDirection === FADE_DIRECTION.right) {
                const nextDiv = document.getElementById('feed-today-content-right');

                currentDiv.setAttribute("id", "feed-today-content-left");
                nextDiv.setAttribute("id", "feed-today-content-main");

                const newNextDiv = document.createElement('div');
                newNextDiv.innerHTML = currentDiv.innerHTML;
                newNextDiv.setAttribute("class", "feed-today-content");
                newNextDiv.setAttribute("id", 'feed-today-content-right');
                setTimeout(() => {
                    currentDiv.remove();
                }, ANIMATION_TIME);

                container.append(newNextDiv)
            } else {
                const nextDiv = document.getElementById('feed-today-content-left');

                currentDiv.setAttribute("id", "feed-today-content-right");
                nextDiv.setAttribute("id", "feed-today-content-main");

                const newNextDiv = document.createElement('div');
                newNextDiv.innerHTML = currentDiv.innerHTML;
                newNextDiv.setAttribute("class", "feed-today-content");
                newNextDiv.setAttribute("id", 'feed-today-content-left');
                setTimeout(() => {
                    currentDiv.remove();
                }, ANIMATION_TIME);

                container.append(newNextDiv)
            }
        }
    }, [feedToday])

    const nextFeedBanner = () => {
        setCooltime(true);
        setFeedFadeDirection(FADE_DIRECTION.right);
        setFeedToday(feedToday => (feedToday % BANNER_WRITING_COUNT) + 1);

        setTimeout(() => {
            setCooltime(false)
        }, ANIMATION_TIME)
    }

    let flag = false;
    useEffect(() => {
        if (!flag) {
            flag = true;

            let feed_auto_animation = setInterval(nextFeedBanner, FEED_BANNER_MOVE_INTERVAL)
        }
    }, [])

    return (
        <div className="feed-container noselect">
            <div className="feed-today-container" id="feed-today">
                <img src={SampleBackground} />
                <span className="feed-today-container-bg-opacity" />
                <span className="feed-today-container-feather" />
                <div className="feed-today-menu">
                    {
                        Array.from({ length: BANNER_WRITING_COUNT }, (_, i) => i + 1).map((v, i) => {
                            return (
                                <FeedTodayMenu setFeedFadeDirection={setFeedFadeDirection} feedToday={feedToday} setFeedToday={setFeedToday} isSelected={feedToday === v} index={v}
                                    cooltime={cooltime} setCooltime={setCooltime} />
                            )
                        })
                    }
                </div>


                <div className="feed-today-content" id="feed-today-content-left">
                    <div className="feed-today-title">
                        <p>오늘의 칼럼</p>
                        <h1>디지털 시대의 독서: 전자책과 종이책의 장단점</h1>
                    </div>
                    <div className="feed-today-more">
                        <h3>Q. 디지털 독서 경험이 독서습관에 미치는 영향은 어떤 걸까?</h3>
                        <p>전자책은 언제 어디서나 쉽게 접근할 수 있어 독서 빈도를 높일 수 있습니다. 다양한 장르와 제목을 손쉽게 다운로드할 수 있는 점이 큰 장점입니다. 단, 종이책의 물리적 감각과 향기를 선호하는 독자에게는 디지털 독서가 만족스럽지 않을 수 있습니다. </p>
                        <div className="feed-today-read-more-button">
                            <ReadMoreIcon />
                            <p>더 읽기</p>
                        </div>
                    </div>
                </div>
                <div className="feed-today-content" id="feed-today-content-main">
                    <div className="feed-today-title">
                        <p>오늘의 칼럼</p>
                        <h1>디지털 시대의 독서: 전자책과 종이책의 장단점</h1>
                    </div>
                    <div className="feed-today-more">
                        <h3>Q. 디지털 독서 경험이 독서습관에 미치는 영향은 어떤 걸까?</h3>
                        <p>전자책은 언제 어디서나 쉽게 접근할 수 있어 독서 빈도를 높일 수 있습니다. 다양한 장르와 제목을 손쉽게 다운로드할 수 있는 점이 큰 장점입니다. 단, 종이책의 물리적 감각과 향기를 선호하는 독자에게는 디지털 독서가 만족스럽지 않을 수 있습니다. </p>
                        <div className="feed-today-read-more-button">
                            <ReadMoreIcon />
                            <p>더 읽기</p>
                        </div>
                    </div>
                </div>
                <div className="feed-today-content" id="feed-today-content-right">
                    <div className="feed-today-title">
                        <p>오늘의 칼럼</p>
                        <h1>디지털 시대의 독서: 전자책과 종이책의 장단점</h1>
                    </div>
                    <div className="feed-today-more">
                        <h3>Q. 디지털 독서 경험이 독서습관에 미치는 영향은 어떤 걸까?</h3>
                        <p>전자책은 언제 어디서나 쉽게 접근할 수 있어 독서 빈도를 높일 수 있습니다. 다양한 장르와 제목을 손쉽게 다운로드할 수 있는 점이 큰 장점입니다. 단, 종이책의 물리적 감각과 향기를 선호하는 독자에게는 디지털 독서가 만족스럽지 않을 수 있습니다. </p>
                        <div className="feed-today-read-more-button">
                            <ReadMoreIcon />
                            <p>더 읽기</p>
                        </div>
                    </div>
                </div>
            </div>

            <TopFeedLine writings={writings} setWritings={setWritings} />
            <FeedLine topic="홍길동님이 작성한 #사랑 컬렉션" writings={writings} setWritings={setWritings} />
            <TopInspireLine writings={writings} setWritings={setWritings} />
            <FeedLine topic="홍길동님이 스크랩한 #우정 컬렉션" writings={writings} setWritings={setWritings} />
            <TemporarySaveLine writing={writings[0]} />
            <FeedLine topic="홍길동님이 읽은 글과 비슷한 컬렉션을 골라 보았어요!" writings={writings} setWritings={setWritings} />
        </div>
    )
}

export default Feed;