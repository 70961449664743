import React, { useState, useEffect } from 'react';

import { ReactComponent as CheckMarkIcon } from '../icons/checkmark.svg';

const SORT_METHOD = {
    NEW: '최신순',
    OLD: '오래된순',
}

const CHECKBOX = {
    SELECTED: true,
    NOSELECTED: false,
}

const RADIO_BUTTON_STYLE = {
    SELECTED: {
        background: 'rgba(110, 247, 222, 1)',
        border: '1px solid',
    },
    DEFAULT: {
        background: 'none',
        border: '1px solid rgba(82, 82, 90, 1)',
    }
}

const RemovedScreen = (props) => {
    const [checkStatus, setCheckStatus] = useState([false, false, false])
    const [allChecked, setAllChecked] = useState(false);

    const isAllChecked = () => {
        return checkStatus.every(choice => choice)
    }

    useEffect(() => {
        if (isAllChecked()) setAllChecked(true)
        else setAllChecked(false)
    }, [checkStatus.toString()])

    return (
        <div className="clip-screen noselect">
            <h1 id="clip-title">휴지통</h1>

            <div className="clip-content">
                <div className="removed-upper-content">
                    <div className="removed-all-select" onClick={() => {
                        if (!isAllChecked()) {
                            setCheckStatus(Array(checkStatus.length).fill(true))
                        } else {
                            setCheckStatus(Array(checkStatus.length).fill(false))
                        }
                    }}>
                        <div className="login-stay-button" style={isAllChecked() ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                            {isAllChecked() ? <CheckMarkIcon /> : null}
                        </div>
                        <p>전체선택</p>
                    </div>

                    <div className="removed-button-section">
                        <button id="removed-recovery">복원하기</button>
                        <button id="removed-delete">삭제하기</button>
                    </div>
                </div>
                <div className="clipped-writings">
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                    <div className="feed-topic-writing">

                        <div className="feed-topic-writing-lower">
                        </div>

                        <div className="feed-topic-writing-upper">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemovedScreen;