import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from 'axios';

import { ReactComponent as CheckMarkIcon } from '../icons/checkmark.svg';

import BoxImage from '../images/box.png';

const REGISTER_STATUS = {
    PHONE_VERIFY: 1,
    SUBMIT_INFO: 2,
    LICENSE_AGREE: 3,
    COMPLETE: 4,
}

const GENDER = {
    MALE: 1,
    FEMALE: 2,
}

const TERM_STATUS = {
    AGREE: true,
    DISAGREE: false,
}

const RADIO_BUTTON_STYLE = {
    SELECTED: {
        background: 'rgba(110, 247, 222, 1)',
        border: '1px solid',
    },
    DEFAULT: {
        background: 'none',
        border: '1px solid rgba(82, 82, 90, 1)',
    }
}

let verify_timer;

const MIN_YEAR = 1900
const MAX_YEAR = new Date().getFullYear()
const VERIFY_TIME = 180;
const IMP = window.IMP;
const jQuery = window.jQuery;

const RegisterScreen = (props) => {
    const navigate = useNavigate()
    const [registerStatus, setRegisterStatus] = useState(REGISTER_STATUS.PHONE_VERIFY)

    const [registerInfo, setRegisterInfo] = useState({
        'gender': 0,
        'tnc': TERM_STATUS.DISAGREE,
        'personal': TERM_STATUS.DISAGREE,
        'alarm': TERM_STATUS.DISAGREE,
    })

    const [verifyNumber, setVerifyNumber] = useState()

    const handleInput = (title, value) => {
        setRegisterInfo({
            ...registerInfo,
            [title]: value,
        })

        console.log(registerInfo)
    }

    const [verifyTime, setVerifyTime] = useState(VERIFY_TIME);
    let verify_count = 0;

    const isValidPhone = () => {
        return registerInfo.phone && registerInfo.phone.length === 11;
    }

    const makeNewCode = () => {
        verify_count++;

        if (verify_count <= 5) { // 전송
            // if(올바른 전화번호일 경우)
            if (isValidPhone()) {
                alert('인증번호가 전송되었습니다.')
                clearInterval(verify_timer);
                setVerifyTime(VERIFY_TIME);
                verify_timer = setInterval(() => {
                    setVerifyTime(prevTime => prevTime > 0 ? prevTime - 1 : prevTime);
                }, 1000)
            }
        } else {
            alert('너무 많이 시도했습니다. 잠시 후 다시 시도해 주세요.')
            setTimeout(() => {
                verify_count = 0
            }, 1000 * 60);
        }
    }

    /** 인증번호를 검사하고 올바르면 다음 단계로 넘어간다. */
    const handleSubmitCode = () => {
        //if(인증번호가 맞다면)

        //else(인증번호가 틀리다면)
        // alert('인증번호가 일치하지 않습니다.')
    }

    /** 유저 정보를 검사하고 모두 작성했다면 다음 단계로 넘어간다 */
    const handleSubmitInfo = () => {
        // if(아이디, 비번을 올바르게 작성했다면)
        setRegisterStatus(REGISTER_STATUS.LICENSE_AGREE);
        // else
        // alert('정보가 잘못되었습니다.')
    }

    const handleSubmitLicense = () => {
        // if(필명 중복확인 하고 필수약관 동의하면)
        setRegisterStatus(REGISTER_STATUS.COMPLETE);
        // else
        // alert('정보가 잘못되었습니다.')
    }

    const handleRegisterComplete = () => {
        // 회원가입 완료. 튜토리얼 페이지로 이동.

        props.setIsLogined(true)
        navigate('/dashboard/home') // dummy
    }


    const handleSelfIdentification = async () => {
        try {
            IMP.init(process.env.REACT_APP_IAMPORT_IDENTIFICATION_CODE);
            IMP.certification(
                {
                    pg: "inicis_unified",
                    merchant_uid: `test-verification-${crypto.randomUUID()}`,
                },
                function (rsp) {
                    // callback
                    if (rsp.success) {
                        // 인증 성공 시 로직
                        alert('인증 성공')
                        setRegisterStatus(REGISTER_STATUS.SUBMIT_INFO)
                        // jQuery.ajax({
                        //     url: "{서버의 인증 정보를 받는 endpoint}",
                        //     method: "POST",
                        //     headers: { "Content-Type": "application/json" },
                        //     data: { imp_uid: rsp.imp_uid },
                        // });
                    } else {
                        alert('인증 실패. ' + rsp.error_msg)
                    }
                },
            );

            // const response = await PortOne.requestIdentityVerification({
            //     storeId: process.env.REACT_APP_STORE_ID,
            //     identityVerificationId: `test-verification-${crypto.randomUUID()}`,
            //     channelKey: process.env.REACT_APP_IDENTIFICATION_CHANNEL_KEY,
            //     customer: {
            //         address: {
            //             addressLine1: "경기도",
            //             addressLine2: '안산시',
            //         }
            //     },
            //     'bypass': {
            //         'inicis': {
            //             directAgency: 'PASS',
            //             flgFixedUser: 'N',
            //         },
            //     },
            // });
            // console.log(response)

            // // 프로세스가 제대로 완료되지 않은 경우 에러 코드가 존재합니다
            // if (response.code == null) {
            //     return alert(response.message);
            // }
        } catch (error) {
            alert(error)
        }
    }


    return (
        <div className="register-screen">
            {
                registerStatus === REGISTER_STATUS.PHONE_VERIFY ? <div className="register-content noselect">
                    <div className="register-content-desc">
                        <h3>휴대폰 본인인증</h3>
                        <p>본인인증을 위해 아래 [본인인증] 버튼을 클릭해 주세요.</p>

                        <button onClick={() => handleSelfIdentification()}>본인인증</button>
                    </div>

                    <div className="register-basic-info">

                        <div className="register-basic-info-section">
                            <div className="register-basic-info-desc">
                                <p>전화번호</p>
                            </div>
                            <div className="register-basic-info-input">
                                <input type="tel" title="phone" maxLength="11" placeholder="-(하이픈)을 제외하고 입력해주세요"
                                    onChange={(e) => { if (!isNaN(e.target.value)) handleInput(e.target.title, e.target.value) }} value={registerInfo.phone} />
                                <button id="register-name-duplicate" onClick={() => { makeNewCode() }} disabled={!isValidPhone()}>인증번호 전송</button>
                            </div>
                        </div>
                        <div className="register-basic-info-section">
                            <div className="register-basic-info-desc">
                                <p>인증번호</p>
                            </div>
                            <div className="register-basic-info-input">
                                <input type="text" title="phone" maxLength="6" placeholder=""
                                    onChange={(e) => { if (!isNaN(e.target.value)) setVerifyNumber(e.target.value) }} value={verifyNumber} />
                                <p id="register-verify-time">{`${Math.floor(verifyTime / 60).toString().padStart(2, "0")}:${(verifyTime % 60).toString().padStart(2, "0")}`}</p>
                            </div>
                        </div>
                    </div>
                    <button className="register-next-button" disabled={!(isValidPhone() && verifyNumber && verifyNumber.toString().length === 6)}
                        onClick={() => { handleSubmitCode() }}>다음</button>
                </div> : null
            }
            {
                registerStatus === REGISTER_STATUS.SUBMIT_INFO ? <div className="register-content noselect">

                    <div className="register-upper-content">
                        <div className="register-content-desc">
                            <h3>회원정보 입력</h3>
                            <p>기본 정보를 입력해 주세요.</p>
                        </div>
                        <p>*는 필수 입력 항목입니다.</p>
                    </div>
                    <div className="register-basic-info-section">
                        <div className="register-basic-info-desc">
                            <p>아이디</p>
                            <span className="register-neccesary-mark">*</span>
                        </div>
                        <div className="register-basic-info-input">
                            <input type="text" title="id" placeholder="6자 이상의 영문, 숫자 조합"
                                onChange={(e) => handleInput(e.target.title, e.target.value)} value={registerInfo.id} />
                        </div>
                    </div>
                    <div className="register-basic-info-section">
                        <div className="register-basic-info-desc">
                            <p>비밀번호</p>
                            <span className="register-neccesary-mark">*</span>
                        </div>
                        <div className="register-basic-info-input">
                            <input type="password" title="pw" placeholder="6자 이상의 영문, 숫자 조합"
                                onChange={(e) => handleInput(e.target.title, e.target.value)} value={registerInfo.pw} />
                        </div>
                    </div>
                    <div className="register-basic-info-section">
                        <div className="register-basic-info-desc">
                            <p>비밀번호 확인</p>
                            <span className="register-neccesary-mark">*</span>
                        </div>
                        <div className="register-basic-info-input">
                            <input type="password" title="pw-re" placeholder="비밀번호를 한 번 더 입력해주세요" />
                        </div>
                    </div>
                    <div className="register-basic-info-section">
                        <div className="register-basic-info-desc">
                            <p>성별</p>
                            <span className="register-optional-mark">(선택)</span>
                        </div>
                        <div className="register-basic-info-input">
                            <div className="login-stay" onClick={() => {
                                if (registerInfo.gender !== GENDER.MALE) handleInput('gender', GENDER.MALE)
                                else handleInput('gender', 0)
                            }}>
                                <p>남자</p>
                                <div className="login-stay-button" style={registerInfo.gender === GENDER.MALE ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                                    {
                                        registerInfo.gender === GENDER.MALE ? <CheckMarkIcon /> : null
                                    }
                                </div>
                            </div>
                            <div className="login-stay" onClick={() => {
                                if (registerInfo.gender !== GENDER.FEMALE) handleInput('gender', GENDER.FEMALE)
                                else handleInput('gender', 0)
                            }}>
                                <p>여자</p>
                                <div className="login-stay-button" style={registerInfo.gender === GENDER.FEMALE ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                                    {
                                        registerInfo.gender === GENDER.FEMALE ? <CheckMarkIcon /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="register-basic-info-section">
                        <div className="register-basic-info-desc">
                            <p>생년월일</p>
                            <span className="register-optional-mark">(선택)</span>
                        </div>
                        <div className="register-basic-info-input">
                            <select class="register-date-input" title="year" onChange={(e) => { handleInput(e.target.title, e.target.value) }}>
                                <option disabled selected>출생 연도</option>
                                {
                                    [...Array.from({ length: (MAX_YEAR - MIN_YEAR + 1) }, (_, i) => MAX_YEAR - i)].map((v) => {
                                        return (
                                            <option id={v} value={v}>{v}</option>
                                        )
                                    })
                                }
                            </select>
                            <select class="register-date-input" title="month" onChange={(e) => { handleInput(e.target.title, e.target.value) }}>
                                <option disabled selected>월</option>
                                {
                                    [...Array.from({ length: 12 }, (_, i) => i + 1)].map((v) => {
                                        return (
                                            <option id={v} value={v}>{v}</option>
                                        )
                                    })
                                }
                            </select>
                            <select class="register-date-input" title="day" onChange={(e) => { handleInput(e.target.title, e.target.value) }}>
                                <option disabled selected>일</option>
                                {
                                    [...Array.from({ length: new Date(registerInfo.year, registerInfo.month, 0).getDate() }, (_, i) => i + 1)].map((v) => {
                                        return (
                                            <option id={v} value={v}>{v}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <button className="register-next-button" disabled={!(registerInfo.id && registerInfo.pw)}
                        onClick={() => { handleSubmitInfo() }}>다음</button>
                </div> : null
            }

            {
                registerStatus === REGISTER_STATUS.LICENSE_AGREE ? <div className="register-content noselect">
                    <div className="register-terms">
                        <div className="register-upper-content">
                            <div className="register-content-desc">
                                <h3>거의 다 왔어요</h3>
                                <p>마지막 단계에요!</p>
                            </div>
                            <p>(필수) 표시는 필수 동의 항목입니다.</p>
                        </div>
                        <div className="register-basic-info-section">
                            <div className="register-basic-info-desc">
                                <p>필명</p>
                                <span className="register-neccesary-mark"> (필수)</span>
                            </div>
                            <div className="register-basic-info-input">
                                <input type="text" title="name" placeholder="한 글자 이상 적어주세요"
                                    onChange={(e) => handleInput(e.target.title, e.target.value)} value={registerInfo.name} />
                                <button id="register-name-duplicate">중복확인</button>
                            </div>
                        </div>
                        <div className="register-term-section">
                            <div className="register-term-agree-section">
                                <div className="register-basic-info-input" onClick={() => {
                                    if (registerInfo.tnc && registerInfo.personal && registerInfo.alarm) {
                                        setRegisterInfo({
                                            ...registerInfo,
                                            'tnc': TERM_STATUS.DISAGREE,
                                            'personal': TERM_STATUS.DISAGREE,
                                            'alarm': TERM_STATUS.DISAGREE,
                                        })
                                    } else {
                                        setRegisterInfo({
                                            ...registerInfo,
                                            'tnc': TERM_STATUS.AGREE,
                                            'personal': TERM_STATUS.AGREE,
                                            'alarm': TERM_STATUS.AGREE,
                                        })
                                    }
                                }}>
                                    <div className="login-stay-button" style={registerInfo.tnc === TERM_STATUS.AGREE && registerInfo.personal === TERM_STATUS.AGREE && registerInfo.alarm === TERM_STATUS.AGREE ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                                        {
                                            registerInfo.tnc === TERM_STATUS.AGREE ? <CheckMarkIcon /> : null
                                        }
                                    </div>
                                    <p className="register-term-title" id="register-all-agree-title">전체 약관 동의</p>
                                </div>
                            </div>
                        </div>
                        <div className="register-term-section">
                            <div className="register-term-agree-section">
                                <div className="register-basic-info-input" onClick={() => {
                                    handleInput('tnc', !registerInfo.tnc)
                                }}>
                                    <div className="login-stay-button" style={registerInfo.tnc === TERM_STATUS.AGREE ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                                        {
                                            registerInfo.tnc === TERM_STATUS.AGREE ? <CheckMarkIcon /> : null
                                        }
                                    </div>
                                    <p className="register-term-title">라이프러리 이용약관 동의</p>
                                    <span className="register-neccesary-mark">(필수)</span>
                                </div>
                            </div>
                        </div>
                        <div className="register-term-section">
                            <div className="register-term-agree-section">
                                <div className="register-basic-info-input" onClick={() => {
                                    handleInput('personal', !registerInfo.personal)
                                }}>
                                    <div className="login-stay-button" style={registerInfo.personal === TERM_STATUS.AGREE ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                                        {
                                            registerInfo.personal === TERM_STATUS.AGREE ? <CheckMarkIcon /> : null
                                        }
                                    </div>
                                    <p className="register-term-title">개인정보 처리 방침 동의</p>
                                    <span className="register-neccesary-mark">(필수)</span>
                                </div>
                            </div>
                        </div>
                        <div className="register-term-section">
                            <div className="register-term-agree-section">
                                <div className="register-basic-info-input" onClick={() => {
                                    handleInput('alarm', !registerInfo.alarm)
                                }}>
                                    <div className="login-stay-button" style={registerInfo.alarm === TERM_STATUS.AGREE ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                                        {
                                            registerInfo.alarm === TERM_STATUS.AGREE ? <CheckMarkIcon /> : null
                                        }
                                    </div>
                                    <p className="register-term-title">뉴스레터, 이벤트 소식 등 알림 동의</p>
                                    <span className="register-optional-mark">(선택)</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <button className="register-next-button" disabled={!(registerInfo.tnc === TERM_STATUS.AGREE && registerInfo.personal === TERM_STATUS.AGREE)}
                        onClick={() => { handleSubmitLicense() }}>회원가입</button>
                </div> : null
            }

            {
                registerStatus === REGISTER_STATUS.COMPLETE ? <div className="register-content noselect">
                    <div className="register-upper-content">
                        <div className="register-content-desc">
                            <h3>회원가입 완료</h3>
                            <p>회원가입이 성공적으로 진행되었어요!</p>
                        </div>
                    </div>
                    <div className="dashboard-box">
                        <img src={BoxImage} />
                    </div>

                    <button className="register-next-button" disabled={!(isValidPhone() && verifyNumber && verifyNumber.toString().length === 6)}
                        onClick={() => { handleRegisterComplete() }}>튜토리얼 시작하기</button>
                </div> : null
            }
        </div >
    )
}

export default RegisterScreen;