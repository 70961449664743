import { useState } from 'react';
import axios from 'axios';
import { useQueries } from 'react-query';
import { NAVER_TOKEN_API, NAVER_USER_INFO_API, LIFERARY_API } from './apis';

const getNaverToken = async (data) => {
    return await axios.post(`https://nid.naver.com/oauth2.0/token?grant_type=authorization_code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&client_secret=${process.env.REACT_APP_NAVER_CLIENT_SECRET}&code=${data.code}&state=${data.state}`)

    // return await NAVER_TOKEN_API.post('/oauth2.0/token', {
    //     grant_type: 'authorization_code',
    //     client_id: process.env.REACT_APP_NAVER_CLIENT_ID,
    //     client_secret: process.env.REACT_APP_NAVER_CLIENT_SECRET,
    //     code: data.code,
    //     state: data.state,
    // })
}

const getNaverUserInfo = async (token) => {
    return await NAVER_USER_INFO_API.post('/v1/nid/me', {}, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            Authorization: `Bearer ${token}`,
        }
    })
}

const getLogginedStatus = async (data) => {
    return await LIFERARY_API.get('/user/naver/callback', {
        params: {
            id: data.user_id,
        }
    })
}

export const useNaverQueries = ({ code, state }) => {
    const [accessToken, setAccessToken] = useState('')
    const [userId, setUserId] = useState('')

    return useQueries([
        {
            queryKey: ['getNaverToken'],
            queryFn: async () =>
                await getNaverToken({
                    code,
                    state
                }),
            onSuccess: async (res) => {
                setAccessToken(res.data.access_token)
            },
            onError: (err) => {
                alert(err)
            },
            refetchOnWindowFocus: false,
        },
        {
            queryKey: ['getNaverUserInfo'],
            enabled: accessToken !== undefined && accessToken.length > 0,
            queryFn: async () => await getNaverUserInfo(accessToken),
            onSuccess: async (res) => {
                console.log(res.data.response.id)
                setUserId(res.data.response.id)
            },
            onError: (err) => {
            },
            refetchOnWindowFocus: false,
        },
        {
            queryKey: ['getLogginedStatus'],
            enabled: userId !== undefined && userId.length > 0,
            queryFn: async () => await getLogginedStatus({
                user_id: userId,
            }),
            onSuccess: async (res) => {
                console.log(res.data)

                if (res.data === false) { // 간편 회원가입 페이지로 이동.
                    window.location.href = `${process.env.REACT_APP_LIFERARY_CLIENT_URL}/register?type=naver`
                } else {
                    window.localStorage.setItem('token', JSON.stringify(res.data))
                }
            },
            refetchOnWindowFocus: false,
        }
    ])
}