import React, { useEffect, useState } from 'react';
import SideNavBar from './SideNavBar.jsx'
import ValueMap from './ValueMap.jsx'
import Branch from './Branch.jsx'
import BoxImage from '../images/box.png';
import { Outlet } from 'react-router-dom';

import { ReactComponent as ValueMapIcon } from '../icons/valuemap-switch.svg';
import { ReactComponent as BranchIcon } from '../icons/branch.svg';
import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { ReactComponent as ExpandIcon } from '../icons/expand.svg';

import { Footer } from './Footer.jsx';

const DashboardHome = () => {
    const valueMapMode = {
        VALUEMAP: false,
        BRANCH: true,
    }
    Object.freeze(valueMapMode);
    const [boxMessage] = useState('안녕 돌아왔구나!')
    const [valueMapState, setValueMapState] = useState(valueMapMode.VALUEMAP);
    const [mySentenceText, setMySentenceText] = useState('남의 의견에 휘둘리지 않고, 주체적으로 판단하는 삶')
    const [clippedQuestionText, setClippedQuestionText] = useState(['당신은 행복을 추구하면서 어떤 희생을 감수해야 한다고 생각하나요?', '당신의 과거 경험 중 어떤 순간이 믿음과 행복의 개념에 큰 영향을 미쳤나요?', '당신의 믿음이 변화하는 과정에서 어떤 새로운 시각이나 아이디어를 발견했나요?', '당신은 행복의 개념을 재정의하기 위해 어떤 질문을 스스로에게 던져야 할까요?'])
    const [notificationText, setnotificationText] = useState([
        {
            text: '도서 <라이프러리> 출간 이벤트 8.16~9.15',
            date: '2024.08.15',
        },
        {
            text: '새 스토리 업데이트!',
            date: '2024.08.16',
        },
        {
            text: '라이프러리 신규 출시 이벤트! 인스타그램 업로드하면 100 크레딧 제공!',
            date: '2024.08.17',
        }])
    const [mySentencePopup, setMySentencePopup] = useState(false)



    return (
        <>
            <div className="dashboard-home-container noselect">

                <div className="dashboard-my-sentence">
                    <div className="dashboard-upper-content noselect">
                        <div className="dashboard-description">
                            <h3>나의 문장</h3>
                            <p>내가 지향하는 삶의 모습을 한 문장으로 적어보세요</p>
                        </div>
                        {/* <div className="dashboard-right-upper-button">
                        <EditIcon fill="rgba(228, 228, 231, 1)" />
                    </div> */}
                    </div>
                    <div className="dashboard-my-sentence-text dashboard-center">
                        <h1 onClick={() => setMySentencePopup(true)}>{mySentenceText}</h1>
                    </div>
                </div>
                <div className="dashboard-question-clip">
                    <div className="dashboard-upper-content noselect">
                        <div className="dashboard-description">
                            <h3>질문 스크랩</h3>
                            <p>생각을 깨우는 질문으로 글을 시작해 보세요</p>
                        </div>
                        {/* <div className="dashboard-right-upper-button">
                        <ExpandIcon fill="rgba(228, 228, 231, 1)" />
                    </div> */}
                    </div>
                    <div className="dashboard-question-clip-text dashboard-center">
                        <ul>
                            {
                                clippedQuestionText.map((v, i) => {
                                    return (
                                        <>
                                            <li>{v}</li>
                                            {
                                                i < clippedQuestionText.length - 1 ? <div className="dashboard-line-division" /> : null
                                            }
                                        </>


                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="dashboard-notification">
                    <div className="dashboard-upper-content noselect">
                        <div className="dashboard-description">
                            <h3>알림</h3>
                        </div>

                    </div>
                    <div className="dashboard-notification-text dashboard-center">
                        {
                            notificationText.map((v, i) => {
                                return (
                                    <>
                                        <div className="dashboard-notification-content">
                                            <p>{v.text}</p>
                                            <p>{v.date}</p>
                                        </div>
                                        {
                                            i < notificationText.length - 1 ? <div className="dashboard-line-division" /> : null
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="dashboard-box">
                    <img src={BoxImage} />
                    <div className="dashboard-box-message">{boxMessage}</div>
                </div>

                <div className="dashboard-value-map">
                    <div className="dashboard-upper-content noselect">
                        <div className="dashboard-description">
                            <h3>{valueMapState === valueMapMode.VALUEMAP ? '가치맵' : '브랜치'}</h3>
                        </div>
                        <div className="value-map-toggle-content">
                            <label className="value-map-toggle-switch">
                                <span className="value-map-toggle-icon">
                                    <ValueMapIcon fill={valueMapState === valueMapMode.VALUEMAP ? 'rgba(63, 63, 70, 1)' : 'rgba(212, 212, 216, 1)'} />
                                </span>
                                <span className="value-map-toggle-icon">
                                    <BranchIcon fill={valueMapState === valueMapMode.BRANCH ? 'rgba(63, 63, 70, 1)' : 'rgba(212, 212, 216, 1)'} />
                                </span>
                                <input type="checkbox" id="value-map-toggle-input" checked={valueMapState} onClick={() => { setValueMapState(!valueMapState) }} />
                                <span className="value-map-toggle-button"></span>
                            </label>

                        </div>
                    </div>
                    <div className="dashboard-inner-content">
                        {
                            valueMapState === valueMapMode.VALUEMAP ? <ValueMap viewOnly={true} /> : <Branch />
                        }
                    </div>
                </div>
            </div>

            {

                mySentencePopup ? <div className="dashboard-my-sentence-popup-background" onClick={() => setMySentencePopup(false)}>
                    <div className="dashboard-my-sentence-popup">
                        <div className="dashboard-my-sentence-popup-content noselect">
                            <div className="dashboard-description">
                                <h3>나의 문장</h3>
                                <p>내가 지향하는 삶의 모습을 한 문장으로 적어보세요</p>
                            </div>
                        </div>
                    </div >
                </div> : null
            }
        </>
    )
}

const Dashboard = (props) => {
    return (
        <div className="dashboard">
            <SideNavBar setIsLogined={props.setIsLogined} />
            <div className="dashboard-container">
                <Outlet />
                <Footer />
            </div>

        </div>
    )
}


export { Dashboard, DashboardHome };