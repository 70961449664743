import React, { useEffect, useState } from 'react';
import { ReactComponent as BranchLine } from '../icons/branch-line.svg';

const VALUE_COLORS = ['rgba(186, 186, 186, 1)', 'rgba(174, 219, 209, 1)', 'rgba(158, 167, 234, 1)', 'rgba(185, 212, 239, 1)', 'rgba(235, 194, 214, 1)', 'rgba(209, 209, 220, 1)', 'rgba(219, 203, 251, 1)', 'rgba(255, 255, 255, 1)']

const BranchValue = (props) => {
    const circleSize = [0, 3, 4.2, 5.5]
    const SHADOW_EXPAND_RATE = 1.3

    return (
        <div className="branch-value-container" style={{ top: props.y + '%' }}>
            <span className="branch-value-line" />
            <span className="branch-value-circle-shadow" style={{
                width: circleSize[props.size] * SHADOW_EXPAND_RATE + 'vmin', height: circleSize[props.size] * SHADOW_EXPAND_RATE + 'vmin',
                background: VALUE_COLORS[props.type], left: (7 - circleSize[props.size] * SHADOW_EXPAND_RATE / 2) + 'vmin', top: (6.2 - circleSize[props.size] * SHADOW_EXPAND_RATE / 2) + 'vmin'
            }} />
            <span className="branch-value-circle" style={{
                width: circleSize[props.size] + 'vmin', height: circleSize[props.size] + 'vmin',
                left: (7 - circleSize[props.size] / 2) + 'vmin', top: (6.2 - circleSize[props.size] / 2) + 'vmin',
            }} />
            <div className="branch-value-texts">
                <h3>{`#${props.name}`}</h3>
                <p>{"중시"}하는 가치</p> {/**이놈도 타입에 맞춰서 바꿔야 함. */}
            </div>
        </div>
    )
}

const BranchMenu = (props) => {


    return (
        <div className="feed-today-menu-button" />
    )
}

const Branch = (props) => {
    const [branchData, setBranchData] = useState({
        name: `Branch 1`,
        goal: '가장 큰 목표',
        values: [],
    })
    const [page, setPage] = useState(0);

    let flag = false;
    useEffect(() => {
        if (!flag) {
            flag = true;
            let values = []
            for (let i = 1; i <= 5; i++) {
                values.push({
                    name: `value${i}`,
                    type: Math.floor(Math.random() * 7.9), /* 0: 형성, 1: 중시, 2: 외면 */
                    size: Math.floor(Math.random() * 2 + 1), /* 1 : 작음, 2 : 중간, 3 : 큼 */
                    y: i * 17,
                });
            }

            setBranchData({
                'name': `Branch 1`,
                'goal': '가치',
                // 'goal color': ??,
                'values': values,
            })
        }

    }, [])


    return (
        <>
            <div className="branches">
                <div className="branch-container noselect">
                    <BranchLine />
                    <div className="branch-goal" style={{ background: VALUE_COLORS[Math.floor(Math.random() * VALUE_COLORS.length)], }}><p>{branchData.goal}</p></div>
                    {
                        [...branchData.values].map((v, i) => {
                            return (
                                <BranchValue name={v.name} type={v.type} size={v.size} y={v.y} />
                            )
                        })
                    }
                </div>
                <div className="branch-container noselect">
                    <BranchLine />
                    <div className="branch-goal" style={{ background: VALUE_COLORS[Math.floor(Math.random() * VALUE_COLORS.length)], }}><p>가장 큰 목표</p></div>
                    <BranchValue name="yeah" area="" size={1} y={10} />
                </div>
            </div>

            <div className="branch-menu">
                {
                    Array.from({ length: Math.ceil(branchData.values.length / 2) }).map((v, i) => {
                        return (
                            <BranchMenu />
                        )
                    })
                }
            </div>
        </>
    )
}

export default Branch;