

export const Footer = () => {

    return (
        <footer className="liferary-footer">
            <div className="footer-info">
                <h3 className="footer-title">Information</h3>
                <p className="footer-desc">상호명: BOX-er<br />사업자등록번호: 732-59-00764<br />대표자명: 권유빈<br />주소: [22000] 인천광역시 연수구 해송로30번길 20 (송도동, 송도 웰카운티 4단지)<br />전화번호: 070-8098-4821<br />통신판매업 신고번호: 2024-인천연수구-3292</p>
            </div>
            <div className="footer-right">
                <div className="footer-slogan">
                    <h3 className="footer-title">SLOGAN</h3>
                    <p className="footer-desc">Cause We Are Human, Liferary<br />당신의 주체성 있는 삶을 돕습니다.</p>
                </div>
                <div className="footer-contact">
                    <h3 className="footer-title">문의사항 및 Contact</h3>
                    <p className="footer-desc">Mon-Fri AM 09:00 ~ PM 19:00<br />liferary2023@gmail.com</p>
                </div>
            </div>
        </footer>
    )
}