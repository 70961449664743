import React, { useState, useEffect } from 'react';

import { ReactComponent as CreditIcon } from '../icons/credit.svg';
import { ReactComponent as RightArrowIcon } from '../icons/right-arrow.svg';
import { ReactComponent as InfoIcon } from '../icons/info.svg';
import { ReactComponent as CreditCardIcon } from '../icons/credit-card.svg';
import { ReactComponent as QrCodeIcon } from '../icons/qrcode.svg';
import { LIFERARY_API } from '../apis/apis.js'

import * as PortOne from "@portone/browser-sdk/v2";
import axios from 'axios';

const SUBSCRIPTIONS = {
    FREE: {
        title: 'FREE',
        fee: 0,
        desc: '자아탐색을 시작해보는 단계',
        color: 'rgb(255, 255, 255)',
    },
    BASIC: {
        title: 'BASIC',
        fee: 4900,
        desc: '자아탐색에 본격 진입한 단계',
        color: 'rgb(159, 255, 185)',
    },
    PRO: {
        title: 'PRO',
        fee: 6900,
        desc: '자아탐색에 몰입한 단계',
        color: 'rgb(110, 247, 222)',
    }
}

Object.freeze(SUBSCRIPTIONS)


export const SubscriptionScreen = () => {
    const [userSub, setUserSub] = useState(SUBSCRIPTIONS.FREE);
    const [userCredit, setUserCredit] = useState(51)
    const [isPayMethodOpened, setIsPayMethodOpened] = useState(false)
    const [isPayListOpened, setIsPayListOpened] = useState(false)

    useEffect(() => {
        // 유저의 이메일, 이름, 전화번호
        // 유저의 구독, 크레딧 정보를 서버에서 갖고 와야 함.
    }, [])

    const [billingKey, setBillingKey] = useState('')

    const handleAddPayMethod = async () => {
        const issueResponse = await PortOne.requestIssueBillingKey({
            storeId: process.env.REACT_APP_STORE_ID,
            channelKey: process.env.REACT_APP_GENERAL_CHANNEL_KEY,
            isTestChannel: true,
            billingKeyMethod: "CARD",
            issueId: "test-issueId",
            issueName: "라이프러리 결제 수단 등록",
            customer: {
                customerId: `unique-customer-id-${Math.floor(Math.random() * 100000)}`,
                fullName: "홍길동",
            }
        });
        console.log(issueResponse)
        // 빌링키가 제대로 발급되지 않은 경우 에러 코드가 존재합니다
        if (issueResponse.code != null) {
            return console.log(issueResponse.message);
        } else {
            console.log(issueResponse.code, issueResponse.billingKey)
            setBillingKey(issueResponse.billingKey)
        }
    }

    const handleSubscriptionPay = async (subscriptionInfo) => {
        if (billingKey.length > 0) { // 이미 결제 수단이 있다면 해당 수단으로 구독 결제를 진행
            if (window.confirm(`[국민 1234] 결제 수단으로 월 ${subscriptionInfo.fee}원 결제를 진행합니다. 승인하시겠습니까?`)) {
                console.log('승인')

                LIFERARY_API.post('/user/billingKey', {},
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                            // Authorization: `Bearer ${accessToken}`
                        }
                    })
            }
        } else { // 아니면 결제 수단을 먼저 추가함.
            alert("등록한 결제 수단이 없습니다. 결제 수단을 먼저 추가해야 합니다.")
            handleAddPayMethod();
        }

        // const options = {
        //     method: 'get',
        //     url: 'https://api.portone.io/billing-keys/' + issueResponse.billingKey,
        //     headers: { 'Content-Type': 'application/json' },
        //     params: { storeId: process.env.REACT_APP_STORE_ID, },
        //     data: {},
        // };

        // try {
        //     const { data } = await axios.request(options);
        //     alert(data);
        // } catch (error) {
        //     alert(error.message);
        // }
    }

    const CREDIT_CHARGE_SETTINGS = {
        CHARGE_METHOD: {
            CREDIT_CARD: 0,
            SIMPLE: 1,
        },
        CREDIT1: {
            title: '10 크레딧',
            credits: 10,
            price: 1000,
        },
        CREDIT2: {
            title: '30 + 5 크레딧',
            credits: 30,
            price: 3000,
        },
        CREDIT3: {
            title: '50 + 10 크레딧',
            credits: 60,
            price: 5000,
        },
    }
    const [creditMode, setCreditMode] = useState(0);
    const [creditCharge, setCreditCharge] = useState({
        chargeMethod: CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.CREDIT_CARD,
        credit: {
            price: 0,
        },
    })
    const CREDIT_BUTTON_STYLE = { background: 'rgba(244, 244, 245, 1)', color: 'rgba(39, 39, 42, 1)', fontWeight: 'bold', }

    const handleCreditMode = (mode) => {
        setCreditMode(mode)
    }

    const handleCreditSetting = (title, value) => {
        setCreditCharge({
            ...creditCharge,
            [title]: value,
        })
    }

    const handleCreditCharge = async () => {
        if (creditCharge.credit.price === 0) {
            return alert('크레딧을 선택해 주세요.')
        }

        if (creditCharge.chargeMethod === CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.CREDIT_CARD) {
            const response = await PortOne.requestPayment({
                storeId: process.env.REACT_APP_STORE_ID,
                channelKey: process.env.REACT_APP_GENERAL_CHANNEL_KEY,
                isTestChannel: true,
                paymentId: `payment${Math.random().toString(36).slice(2)}`,
                orderName: `라이프러리 ${creditCharge.credit.title}`,
                totalAmount: creditCharge.credit.price,
                currency: "CURRENCY_KRW",
                payMethod: "CARD",
            });
        } else if (creditCharge.chargeMethod === CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.SIMPLE) {
            const response = await PortOne.requestPayment({
                // Store ID 설정
                storeId: process.env.REACT_APP_STORE_ID,
                // 채널 키 설정
                channelKey: "channel-key-73036aa5-9b0d-4722-9e1a-d899fec635b5",
                paymentId: `payment-${crypto.randomUUID()}`,
                orderName: `라이프러리 ${creditCharge.credit.title}`,
                totalAmount: creditCharge.credit.price,
                currency: "CURRENCY_KRW",
                payMethod: "EASY_PAY",
            });
        }



    }

    /** 유저의 결제 수단을 삭제한다. */
    const handleMethodDelete = () => {
        alert('결제 수단을 삭제하시겠습니까? 이미 구독 중인 경우 결제 수단을 다시 등록해야 구독이 유지됩니다.')

        // 삭제 절차 (서버 통신)

        alert("결제 수단이 삭제되었습니다.")
    }

    return (
        <div className="subscription-screen noselect">
            <div className="now-subscription">
                <h3 className="subscription-title">현재 홍길동님의 플랜은</h3>
                <div className="now-subscription-content">
                    <div className="now-subscription-section">
                        <span>
                            <h3 className="subscription-name" style={{ color: userSub.color }}>{userSub.title}</h3>
                            <p className="subscription-fee">{`₩${userSub.fee} / 월`}</p>
                        </span>
                        <p className="subscription-desc">{userSub.desc}</p>
                    </div>
                    <div className="now-credit-section">
                        <span className="now-credit-info">
                            <p>크레딧</p>
                            <InfoIcon fill="rgba(212, 212, 216, 1)" />
                            <div className="credit-info">
                                <h3>크레딧이란?</h3>
                                <span>
                                    <p>크레딧은 언제 소모되나요?</p>
                                    <li>추천 질문 생성 시 <CreditIcon fill="rgba(161, 161, 169, 1)" /> 1</li>
                                    <li>브랜치 생성 시 <CreditIcon fill="rgba(161, 161, 169, 1)" /> 3</li>
                                </span>

                                <span>
                                    <p>어떻게 얻을 수 있나요?</p>
                                    <li>내 글이 영감글로 선정될 시 <CreditIcon fill="url(#Gradient1)" /><span className="credit-gradient">2</span></li>
                                    <li>이번 주 빛난 글 선정 시</li>
                                    <span>
                                        <p>1등 <CreditIcon fill="url(#Gradient1)" /><span className="credit-gradient">10</span></p>
                                        <p>2등 <CreditIcon fill="url(#Gradient1)" /><span className="credit-gradient">7</span></p>
                                        <p>3등 <CreditIcon fill="url(#Gradient1)" /><span className="credit-gradient">5</span></p>
                                        <p>4~7등 <CreditIcon fill="url(#Gradient1)" /><span className="credit-gradient">3</span></p>
                                    </span>
                                </span>
                            </div>
                        </span>
                        <span>
                            <CreditIcon fill="url(#Gradient1)" />
                            <h3>51</h3>
                        </span>


                    </div>
                </div>
            </div>

            <div className="all-subscription">
                <h3 className="subscription-title">모든 플랜</h3>
                <div className="all-subscription-section">
                    <div className="subscription-info">
                        <span>
                            <h3 className="subscription-name" style={{ color: SUBSCRIPTIONS.FREE.color }}>{SUBSCRIPTIONS.FREE.title}</h3>
                            <p className="subscription-fee">{`₩${SUBSCRIPTIONS.FREE.fee} / 월`}</p>
                        </span>

                        <ul>
                            <li><span><CreditIcon fill="rgba(217, 217, 217, 1)" />5 / 월 제공</span></li>
                            <li><span>유형별 가치 2개 생성 가능</span></li>
                            <li><span>무제한 글 생성 가능</span></li>
                            <li><span>브랜치 생성 가능</span></li>
                        </ul>
                    </div>
                    <div className="subscription-info">
                        <span>
                            <h3 className="subscription-name" style={{ color: SUBSCRIPTIONS.BASIC.color }}>{SUBSCRIPTIONS.BASIC.title}</h3>
                            <p className="subscription-fee">{`₩${SUBSCRIPTIONS.BASIC.fee} / 월`}</p>
                        </span>

                        <ul>
                            <li><span><CreditIcon fill="rgba(217, 217, 217, 1)" />30 / 월 제공</span></li>
                            <li><span>유형별 가치 5개 생성 가능</span></li>
                            <li><span>무제한 글 생성 가능</span></li>
                            <li><span>브랜치 생성 가능</span></li>
                            <li><span>오리지널 컨텐츠 <span style={{ color: SUBSCRIPTIONS.BASIC.color }}>베이직</span> 열람</span></li>
                        </ul>

                        {userSub.title !== SUBSCRIPTIONS.BASIC.title ? <button className="subscription-update-button" style={{ color: SUBSCRIPTIONS.BASIC.color }} onClick={() => handleSubscriptionPay(SUBSCRIPTIONS.BASIC)}>플랜 변경</button> : null}
                    </div>
                    <div className="subscription-info">
                        <span>
                            <h3 className="subscription-name" style={{ color: SUBSCRIPTIONS.PRO.color }}>{SUBSCRIPTIONS.PRO.title}</h3>
                            <p className="subscription-fee">{`₩${SUBSCRIPTIONS.PRO.fee} / 월`}</p>
                        </span>

                        <ul>
                            <li><span><CreditIcon fill="rgba(217, 217, 217, 1)" />50 / 월 제공</span></li>
                            <li><span>유형별 가치 10개 생성 가능</span></li>
                            <li><span>무제한 글 생성 가능</span></li>
                            <li><span>브랜치 생성 가능</span></li>
                            <li><span>오리지널 컨텐츠 <span style={{ color: SUBSCRIPTIONS.PRO.color }}>프로</span> 열람</span></li>
                            <li><span>오리지널 주간 질문 제공</span></li>
                        </ul>

                        {userSub.title !== SUBSCRIPTIONS.PRO.title ? <button className="subscription-update-button" style={{ color: SUBSCRIPTIONS.PRO.color }} onClick={() => handleSubscriptionPay(SUBSCRIPTIONS.PRO)}>플랜 변경</button> : null}
                    </div>
                </div>
            </div>

            <div className="subscription-credit">
                <h3 className="subscription-title">크레딧 관리</h3>
                <div className="subscription-credit-section">
                    <div className="subscription-credit-menu">
                        <button onClick={() => handleCreditMode(0)} style={creditMode === 0 ? CREDIT_BUTTON_STYLE : null}>크레딧 충전</button>
                        <button onClick={() => handleCreditMode(1)} style={creditMode === 1 ? CREDIT_BUTTON_STYLE : null}>사용 내역</button>
                    </div>
                    {
                        creditMode === 0 ? <><div className="subscription-credit-charge">
                            <div className="settings-info-item">
                                <p style={{ width: '23%', }}>결제 수단 선택</p>
                                <button onClick={() => handleCreditSetting('chargeMethod', CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.CREDIT_CARD)} style={creditCharge.chargeMethod === CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.CREDIT_CARD ? CREDIT_BUTTON_STYLE : null}><CreditCardIcon fill={creditCharge.chargeMethod === CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.CREDIT_CARD ? CREDIT_BUTTON_STYLE.color : CREDIT_BUTTON_STYLE.background} />신용카드</button>
                                <button onClick={() => handleCreditSetting('chargeMethod', CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.SIMPLE)} style={creditCharge.chargeMethod === CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.SIMPLE ? CREDIT_BUTTON_STYLE : null}><QrCodeIcon fill={creditCharge.chargeMethod === CREDIT_CHARGE_SETTINGS.CHARGE_METHOD.SIMPLE ? CREDIT_BUTTON_STYLE.color : CREDIT_BUTTON_STYLE.background} />간편결제</button>
                            </div>
                            <div className="settings-info-item">
                                <p style={{ width: '23%', }}>충전 크레딧</p>
                                <button onClick={() => handleCreditSetting('credit', CREDIT_CHARGE_SETTINGS.CREDIT1)} style={creditCharge.credit.price === CREDIT_CHARGE_SETTINGS.CREDIT1.price ? CREDIT_BUTTON_STYLE : null}>{CREDIT_CHARGE_SETTINGS.CREDIT1.title}</button>
                                <button onClick={() => handleCreditSetting('credit', CREDIT_CHARGE_SETTINGS.CREDIT2)} style={creditCharge.credit.price === CREDIT_CHARGE_SETTINGS.CREDIT2.price ? CREDIT_BUTTON_STYLE : null}>{CREDIT_CHARGE_SETTINGS.CREDIT2.title}</button>
                                <button onClick={() => handleCreditSetting('credit', CREDIT_CHARGE_SETTINGS.CREDIT3)} style={creditCharge.credit.price === CREDIT_CHARGE_SETTINGS.CREDIT3.price ? CREDIT_BUTTON_STYLE : null}>{CREDIT_CHARGE_SETTINGS.CREDIT3.title}</button>
                            </div>
                            <div className="settings-info-item">
                                <p style={{ width: '23%', }}>결제 금액</p>
                                <p>{`₩${creditCharge.credit.price}`}</p>
                            </div>

                        </div>
                            <button id="subscription-credit-charge-button" onClick={() => handleCreditCharge()}>충전</button></> : <div>
                        </div>
                    }
                </div>
            </div>

            <div className="subscription-pay-method">
                <span className="subscription-upper-content" onClick={() => setIsPayMethodOpened(!isPayMethodOpened)}>
                    <h3 className="subscription-title">결제 수단</h3>
                    <RightArrowIcon className={isPayMethodOpened ? "arrow-opened" : null} fill="rgba(161, 161, 169, 1)" />
                </span>
                {
                    isPayMethodOpened ? <div className="subscription-pay-method-content">
                        {
                            billingKey.length === 0 ? <span className="no-pay-method" onClick={() => handleAddPayMethod()}>
                                <p>결제 수단이 없습니다. 새로운 결제 수단을 추가해 주세요.</p>
                                <button>추가하기</button>
                            </span> : <table className="subscription-table">
                                <tr>
                                    <th>카드번호</th>
                                    <th>유효기간</th>
                                </tr>
                                <tr>
                                    <td>국민 1234</td>
                                    <td>99/99</td>
                                    <td><button onClick={() => handleMethodDelete()}>삭제하기</button></td>
                                </tr>
                            </table>
                        }
                    </div> : null
                }
            </div>

            <div className="subscription-pay-list">
                <span className="subscription-upper-content" onClick={() => setIsPayListOpened(!isPayListOpened)}>
                    <h3 className="subscription-title">결제 내역</h3>
                    <RightArrowIcon className={isPayListOpened ? "arrow-opened" : null} fill="rgba(161, 161, 169, 1)" />
                </span>

                {
                    isPayListOpened ? <div className="subscription-pay-method-content">

                        <table className="subscription-table">
                            <tr>
                                <th>주문번호</th>
                                <th>날짜</th>
                                <th>유형</th>
                                <th>상태</th>
                                <th>총계</th>
                            </tr>
                            <tr>
                                <td>24901824091</td>
                                <td>24-09-09</td>
                                <td>구독권결제</td>
                                <td>완료됨</td>
                                <td>4900원</td>
                                <td><button>보기</button></td>
                            </tr>
                        </table>
                    </div> : null
                }
            </div>
        </div>
    )
}