import axios from 'axios';

export const LIFERARY_API = axios.create({
    baseURL: `${process.env.REACT_APP_LIFERARY_SERVER_URL}`
})

export const KAKAO_TOKEN_API = axios.create({
    baseURL: "https://kauth.kakao.com",
    headers: { 'Content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
})

export const KAKAO_USER_INFO_API = axios.create({
    baseURL: "https://kapi.kakao.com"
})

export const NAVER_TOKEN_API = axios.create({
    baseURL: "https://nid.naver.com"
})

export const NAVER_USER_INFO_API = axios.create({
    baseURL: "https://openapi.naver.com"
})