import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ValueVertex = (props) => {
    const [postPosition, setPostPosition] = useState({
        x: 100,
        y: 100,
    })

    const [radius, setRadius] = useState(10);

    const changePos = (newPos) => {
        let newVertexes = props.vertexes.map((v, i) => {
            return (
                props.index == i ? { ...newPos } : { ...v, clicked: false }
            )
        })
        props.setVertexes(newVertexes);
        props.setEdges([])
    }

    const handlePointerDown = e => {
        const el = e.target;
        const bbox = e.target.getBoundingClientRect();
        const x = e.clientX - bbox.left;
        const y = e.clientY - bbox.top;
        el.setPointerCapture(e.pointerId);

        setPostPosition({
            x: props.position.x,
            y: props.position.y,
        });

        const newPos = {
            ...props.position,
            active: true,
            offset: {
                x,
                y
            }
        }
        changePos(newPos);
    };
    const handlePointerMove = e => {
        const bbox = e.target.getBoundingClientRect();
        const x = e.clientX - bbox.left;
        const y = e.clientY - bbox.top;
        if (props.position.active) {
            const newPos = {
                ...props.position,
                x: props.position.x - (props.position.offset.x - x),
                y: props.position.y - (props.position.offset.y - y),
                clicked: false,
            }

            newPos.x = newPos.x > 1000 - radius * 7 ? 1000 - radius * 7 : newPos.x;
            newPos.x = newPos.x < 0 + radius ? 0 + radius : newPos.x;

            newPos.y = newPos.y > 600 - radius ? 600 - radius : newPos.y;
            newPos.y = newPos.y < 0 + radius ? 0 + radius : newPos.y;
            changePos(newPos)
        }
    };
    const handlePointerUp = e => {
        const newPos = {
            ...props.position,
            active: false
        }
        changePos(newPos);
    };

    const handleClick = e => {
        if (postPosition.x === props.position.x && postPosition.y === props.position.y) {
            const newPos = {
                ...props.position,
                clicked: true
            }
            changePos(newPos);

            makeSpanningTree();
        } else {
            props.setEdges([]);
        }

    }

    const makeSpanningTree = () => {
        const vertices = props.vertexes.map((v) => ({ 'x': v.x, 'y': v.y }));

        function getAllEdges(vertices) {
            const edges = [];
            for (let i = 0; i < vertices.length; i++) {
                for (let j = i + 1; j < vertices.length; j++) {
                    edges.push({
                        start: vertices[i],
                        end: vertices[j],
                        distance: calculateDistance(vertices[i], vertices[j])
                    });
                }
            }
            return edges;
        }

        const edges = getAllEdges(vertices);

        // 두 점 사이의 거리를 계산하는 함수
        function calculateDistance(point1, point2) {
            return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
        }

        // Prim's Algorithm을 이용하여 Minimum Spanning Tree를 찾는 함수
        function primMST(vertices, edges) {
            const mst = [];
            const visited = new Set();
            visited.add(vertices[0]);

            while (visited.size < vertices.length) {
                let minEdge = null;
                let minDistance = Infinity;

                for (const edge of edges) {
                    if (visited.has(edge.start) && !visited.has(edge.end) || visited.has(edge.end) && !visited.has(edge.start)) {
                        const distance = calculateDistance(edge.start, edge.end);
                        if (distance < minDistance) {
                            minDistance = distance;
                            minEdge = edge;
                        }
                    }
                }

                if (minEdge) {
                    mst.push(minEdge);
                    visited.add(minEdge.start);
                    visited.add(minEdge.end);
                }
            }

            return mst;
        }

        // Minimum Spanning Tree를 찾고 결과를 출력합니다.
        const mst = primMST(vertices, edges);
        const result = mst.map(edge => ({ start: edge.start, end: edge.end }));
        props.setEdges(result);

        console.log(result);
    }

    return (
        <>
            <circle
                cx={props.position.x}
                cy={props.position.y}
                r={radius}
                onPointerDown={handlePointerDown}
                onPointerUp={handlePointerUp}
                onPointerMove={handlePointerMove}
                onClick={handleClick}
                fill={props.position.clicked ? "cyan" : props.position.active ? "white" : "grey"}
            />
            <text x={props.position.x + radius * 2} y={props.position.y + radius * 0.5} fill="#eee" class="noselect">{props.position.name}</text>
        </>
    );
}

const Recaptcha = () => {
    const [result, setResult] = useState('');
    const [loginCount, setLoginCount] = useState(0)
    const MAX_LOGIN_COUNT = 5;
    const [email, setEmail] = useState('');
    const [pw, setPw] = useState('')

    const handleClick = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_GRECAPTCHA_SITE_KEY, { action: 'login' })
                .then(async (token) => {
                    await axios.post('https://www.liferaryback.xyz/user/login', {
                        email: email,
                        password: pw,
                    }, {
                        headers: {
                            'x-recaptcha-token': token,
                        }
                    })
                        .then(res => {
                            if (res.data.token) {
                                alert('로그인 성공!')
                            } else {
                                if (loginCount >= MAX_LOGIN_COUNT) {
                                    alert(`의심스러운 행동이 ${MAX_LOGIN_COUNT}회 연속으로 감지되었습니다. 일시적으로 로그인이 제한됩니다.`)
                                } else {
                                    alert('의심스러운 행동이 감지되었습니다. 다시 시도해 주세요.')
                                    setLoginCount(loginCount + 1)
                                }

                            }
                        })
                        .catch(err => {
                            alert(err)
                        })
                    console.log(token)
                })
                .catch(err => {
                    alert(err)
                })
        })
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GRECAPTCHA_SITE_KEY}`;
        script.async = true;
        document.body.appendChild(script);
    }, [])

    return (
        <>
            <h1 style={{ color: "#fff" }}>reCAPTCHA Test Page</h1>
            <div style={{ width: '30vw', height: '25vh', marginTop: '5vh', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column', }}>
                <p style={{ color: '#ddd' }}>이메일</p>
                <input type="text" maxLength="30" placeholder='아이디를 입력하세요' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                <p style={{ color: '#ddd' }}>비밀번호</p>
                <input type="password" maxLength="30" placeholder='비밀번호를 입력하세요' value={pw} onChange={e => { setPw(e.target.value) }} />
                <button onClick={() => handleClick()}>로그인하기</button>
                <h3 style={{ color: '#fff', }}>{result}</h3>
            </div>
        </>
    )
}

const Test = () => {
    const menus = ['가치맵 테스트', 'AI 테스트', 'reCAPTCHA 테스트'];
    const [menuNum, setMenuNum] = useState(1)


    const [vertexes, setVertexes] = useState([])
    const [vertexNum, setVertexNum] = useState(3);
    const [edges, setEdges] = useState([])
    let flag = false;

    const initialize = () => {
        let initVertexes = [];
        for (let i = 0; i < vertexNum; i++) {
            initVertexes.push({
                name: `가치${i + 1}`,
                x: Math.floor(Math.random() * 1000),
                y: Math.floor(Math.random() * 600),
                // size : 1~2개 : 1단계, 3~4개 : 2단계, 5개> : 3단계
                // date
                active: false,
                clicked: false,
                offset: {}
            });
        }

        setVertexes(initVertexes);
        setEdges([])
        console.log('initialize complete')
    }



    useEffect(() => {
        if (!flag) {
            flag = true;

            initialize();

        }
    }, [])

    const [text, setText] = useState('먼저 행복은 때로는 무언가의 희생을 담보로 한다. 만약 사회적인 성공에서 행복을 느끼고 싶다면 주변 사람과의 시간에서 얻어지는 행복은 어느 정도 포기해야 한다. 당장의 행복을 위해 주야장천 놀기만 한다면 훗날 큰 불행을 만날 수 있다. 결국은 행복이란 선택의 문제다. 인내할 수 있을 정도의 고통을 끌어안고 무엇을 선택할지 결정해야 한다. 게다가 행복만 추구하면 역설적으로 행복해질 수 없다. 행복이란 삶에서 얻어지는 부산물 같은 것이다. 그것에만 집착한다면 행복 없는 삶을 무의미한 것으로 보고 조금만 결과가 나오지 않아도 쉬이 우울해진다. 돈을 통해서, 사랑을 통해서, 그럴듯한 세계여행을 통해서 행복을 바로바로 얻어야 하는데 그러지 못하기 때문이다. 이는 앞서 말한 행복의 속성에서도 기인한다. 행복은 결코 지속되지 않는다. 만약 도토리 하나를 먹었다고 겨우내 만족해하는 다람쥐가 있었다면 그 다람쥐는 후손을 남기기는커녕 당장의 생존도 힘들었을 것이다. 우리의 몸은 지속적인 생존 활동을 유도하기 위해 행복을 금방 앗아간다. 연애 초기의 떨림도 곧 사라지고 취업을 했다는 기쁨이 오래가지 않는 이유다. 행복은 결코 지속되지 않는다. 다만 지속적으로 얻어야 하는 그 무언가다.');
    const [answer, setAnswer] = useState(['AI-Generated Text Shows here.'])
    const [userTag, setUserTag] = useState('믿음')

    const aiGenerate = async () => {
        // const params = {
        //     text: "66b98a5f382095696d0e7350",
        //     value: '66946fd18d4f1e695c96a7b7',
        // }
        // await axios.post('https://www.liferaryback.xyz/question/AI', params, {
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // })
        //     .then(res => {
        //         const answerText = res.data;
        //         const purifiedAnswer = answerText.split('?').filter(v => v).map(v => v.replace(/^[\s\uFEFF\xA0]+/gi, '').replace(/^\d+\.\s*/, '') + '?')

        //         setAnswer(purifiedAnswer)
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     })


    }

    return (
        <>
            <div className="test-screen">
                <div id="test-buttons">
                    {
                        menus.map((v, i) => {
                            return (
                                <button style={{ fontSize: '16px', padding: '0.3em 0.5em', margin: '0 0.5em', }} onClick={() => { setMenuNum(i) }}>{v}</button>
                            )
                        })
                    }
                </div>
                {
                    menuNum === 0 ?
                        <>
                            <div>
                                <h1 style={{ color: '#fff' }}>가치맵 별자리 테스트</h1>
                                <input type="number" placeholder="갯수" value={vertexNum} maxLength={100} onChange={(e) => {
                                    if (e.target.value <= e.target.maxLength) {
                                        setVertexNum(e.target.value)
                                    }
                                }} />
                                <input type="submit" value="Shuffle" onClick={() => {
                                    initialize();
                                }} />
                                <p style={{ color: '#ddd' }}>클릭 시 Spanning Tree 보여줌. Shuffle 클릭 시 새 Vertex 생성.</p>
                            </div>
                            <svg viewBox="0 0 1000 600" width="1000" height="600">
                                {
                                    edges.map((v, i) => {
                                        return (
                                            <line x1={v.start.x} y1={v.start.y} x2={v.end.x} y2={v.end.y} stroke={'grey'} />
                                        )
                                    })
                                }
                                {
                                    vertexes.map((v, i) => {
                                        return (
                                            <ValueVertex vertexes={vertexes} setVertexes={setVertexes} setEdges={setEdges} position={v} index={i} />
                                        )
                                    })
                                }

                            </svg>
                        </> : null
                }
                {
                    menuNum === 1 ? <>
                        <h1 style={{ color: "#fff" }}>AI Prompt Test Page</h1>
                        <p style={{ color: '#ddd', lineHeight: '2em', }}>2024/08/12 백엔드 연동 완료. (생성 기능은 막아놓음)</p>
                        <textarea value={text} onChange={(e) => {
                            setText(e.target.value)
                        }} />
                        <div id="test-test">
                            <button id="generate" onClick={() => { aiGenerate() }}>Generate</button>
                        </div>

                        <div id="test-answer">
                            {
                                answer.map((v) => {
                                    return (
                                        <p style={{ color: '#ddd', margin: '1vh 0' }}>{v}</p>
                                    )
                                })
                            }
                        </div>
                    </> : null
                }
                {
                    menuNum === 2 ? <Recaptcha /> : null
                }
            </div>
            {/* <div className="test-screen">
                <div>
                    <h1 style={{ color: '#fff' }}>가치맵 별자리 테스트</h1>
                    <input type="number" placeholder="갯수" value={vertexNum} maxLength={100} onChange={(e) => {
                        if (e.target.value <= e.target.maxLength) {
                            setVertexNum(e.target.value)
                        }
                    }} />
                    <input type="submit" value="Shuffle" onClick={() => {
                        initialize();
                    }} />
                    <p style={{ color: '#ddd' }}>클릭 시 Spanning Tree 보여줌. Shuffle 클릭 시 새 Vertex 생성.</p>
                </div>
                <svg viewBox="0 0 1000 600" width="1000" height="600">
                    {
                        edges.map((v, i) => {
                            return (
                                <line x1={v.start.x} y1={v.start.y} x2={v.end.x} y2={v.end.y} stroke={'grey'} />
                            )
                        })
                    }
                    {
                        vertexes.map((v, i) => {
                            return (
                                <ValueVertex vertexes={vertexes} setVertexes={setVertexes} setEdges={setEdges} position={v} index={i} />
                            )
                        })
                    }

                </svg>
            </div> */}
        </>
    )
}

export default Test;