import React, { useState, useEffect } from 'react';

import { ReactComponent as CreditIcon } from '../icons/credit.svg';
import { ReactComponent as RightArrowIcon } from '../icons/right-arrow.svg';
import { ReactComponent as CheckMarkIcon } from '../icons/checkmark.svg';

const IMP = window.IMP;
const jQuery = window.jQuery;

const SUBSCRIPTIONS = {
    FREE: {
        title: 'FREE',
        fee: '0',
        desc: '자아탐색을 시작해보는 단계',
        color: 'rgb(255, 255, 255)',
    },
    BASIC: {
        title: 'BASIC',
        fee: '4,900',
        desc: '자아탐색에 본격 진입한 단계',
        color: 'rgb(159, 255, 185)',
    },
    PRO: {
        title: 'PRO',
        fee: '6,900',
        desc: '자아탐색에 몰입한 단계',
        color: 'rgb(158, 255, 244)',
    }
}
Object.freeze(SUBSCRIPTIONS)

const GENDER = {
    MALE: 1,
    FEMALE: 2,
}

const TERM_STATUS = {
    AGREE: true,
    DISAGREE: false,
}

const RADIO_BUTTON_STYLE = {
    SELECTED: {
        background: 'rgba(110, 247, 222, 1)',
        border: '1px solid',
    },
    DEFAULT: {
        background: 'none',
        border: '1px solid rgba(82, 82, 90, 1)',
    }
}

const MIN_YEAR = 1900
const MAX_YEAR = new Date().getFullYear()

export const Settings = () => {
    const [userInfo, setUserInfo] = useState({
        subscription: SUBSCRIPTIONS.FREE,
    })
    const [userCredit, setUserCredit] = useState(51)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        // 처음 유저 정보를 여기에서 받아온다.


        if (new URL(window.location.href).searchParams.get('edit')) { // ?edit=true일 경우
            setIsEdit(true)
        }
    }, [])

    const initialInfo = {
        'name': '홍길동',
        'phone': '01011111111',
        'id': 'abcde',
        'gender': GENDER.MALE,
    }

    const [registerInfo, setRegisterInfo] = useState({
        'name': '홍길동',
        'name_verified': true,
        'phone': '01011111111',
        'phone_verified': true,
        'id': 'abcde',
        'gender': GENDER.MALE,
    })

    const handleInput = (title, value) => {
        setRegisterInfo({
            ...registerInfo,
            [title]: value,
        })

        console.log(registerInfo)
    }

    const handleDuplicateCheck = () => {
        if (initialInfo.name !== registerInfo.name) {
            // 검사 절차 진행

            // 중복이 아니면
            handleInput('name_verified', true)
            alert('사용 가능한 필명입니다.')
            //else (중복이면)
            //alert('이미 사용 중인 필명입니다.')
        } else { // 처음과 같은 이름이면..
            handleInput('name_verified', true)
            alert('사용 가능한 필명입니다.')
        }
    }

    const handleIdentification = () => {
        IMP.init(process.env.REACT_APP_IAMPORT_IDENTIFICATION_CODE);
        IMP.certification(
            {
                pg: "inicis_unified",
                merchant_uid: `test-verification-${crypto.randomUUID()}`,
            },
            function (rsp) {
                // callback
                if (rsp.success) {
                    // 인증 성공 시 로직
                    alert('인증 성공')
                    // jQuery.ajax({
                    //     url: "{서버의 인증 정보를 받는 endpoint}",
                    //     method: "POST",
                    //     headers: { "Content-Type": "application/json" },
                    //     data: { imp_uid: rsp.imp_uid },
                    // });
                } else {
                    alert('인증 실패. ' + rsp.error_msg)
                }
            },
        );
    }

    const handleSave = () => {
        if (initialInfo.name !== registerInfo.name && !registerInfo.name_verified) {
            alert('필명 중복 확인을 진행해 주세요.')
        } else if (!registerInfo.currentPw) {
            alert('현재 비밀번호를 입력해 주세요.') // 현재 비번 불일치 로직 추가
        } else if (registerInfo.newPw) {
            if (registerInfo.newPw.length < 6) {
                alert('비밀번호는 6글자 이상이여야 합니다.')
            } else if (registerInfo.newPw !== registerInfo.newPwAgain) {
                alert('비밀번호 확인이 일치하지 않습니다.')
            } else if (registerInfo.newPw === registerInfo.currentPw) {
                alert('기존 비밀번호와 다른 새로운 비밀번호를 설정해 주세요.')
            }
        } else {
            alert('저장되었습니다.')
            window.location.href = `${process.env.REACT_APP_LIFERARY_CLIENT_URL}/dashboard/settings`
        }

    }

    return (
        <div className="settings-screen">
            <div className="settings-user-info">
                <p>라이프러리와 함께 자아를 탐색해 나가고 계시는 <span className="text-bolder">홍길동</span>님은 <span className="text-bolder">[{`${userInfo.subscription.title} Plan`}]</span> 회원이십니다.</p>
                <span><CreditIcon fill="rgba(244, 244, 245, 1)" /><p><span className="text-bolder">{`${userCredit} 크레딧`}</span> 남았습니다.</p></span>

                <button id="settings-plan-button" onClick={() => window.location.href = `${process.env.REACT_APP_LIFERARY_CLIENT_URL}/dashboard/subscription`}>플랜 변경 / 크레딧 추가 구매하기<RightArrowIcon fill="rgba(212, 212, 216, 1)" /></button>
            </div>

            {
                isEdit ? <div className="settings-main">
                    <h3 className="settings-title">기본 정보</h3>
                    <div className="settings-info-item">
                        <p style={{ width: '15%', }}>필명</p>
                        <input className="settings-input" type="text" value={registerInfo.name} onChange={(e) => handleInput('name', e.target.value)} />
                        <button className="settings-duplicate-check" onClick={() => handleDuplicateCheck()}>중복 확인</button>
                    </div>
                    <div className="settings-info-item">
                        <p style={{ width: '15%', }}>아이디</p>
                        <input className="settings-input" type="text" value={registerInfo.id} disabled />
                    </div>
                    <div className="settings-info-item">
                        <p style={{ width: '15%', }} >전화번호</p>
                        <input className="settings-input" type="text" value={registerInfo.phone} disabled />
                        <button className="settings-duplicate-check" onClick={() => handleIdentification()}>인증하기</button>
                    </div>
                    <div className="settings-info-item">
                        <p style={{ width: '15%', }}>현재 비밀번호</p>
                        <input className="settings-input" type="password" value={registerInfo.currentPw} onChange={(e) => handleInput('currentPw', e.target.value)} />
                    </div>
                    <div className="settings-info-item">
                        <p style={{ width: '15%', }}>변경할 비밀번호</p>
                        <input className="settings-input" type="password" value={registerInfo.newPw} onChange={(e) => handleInput('newPw', e.target.value)} />
                        <p style={{ width: '15%', }}>비밀번호 확인</p>
                        <input className="settings-input" type="password" value={registerInfo.newPwAgain} onChange={(e) => handleInput('newPwAgain', e.target.value)} />
                    </div>
                    <div className="settings-info-item">
                        <p style={{ width: '15%', }}>성별</p>
                        <div className="login-stay" onClick={() => {
                            if (registerInfo.gender !== GENDER.MALE) handleInput('gender', GENDER.MALE)
                            else handleInput('gender', 0)
                        }}>
                            <p>남자</p>
                            <div className="login-stay-button" style={registerInfo.gender === GENDER.MALE ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                                {
                                    registerInfo.gender === GENDER.MALE ? <CheckMarkIcon /> : null
                                }
                            </div>
                        </div>
                        <div className="login-stay" onClick={() => {
                            if (registerInfo.gender !== GENDER.FEMALE) handleInput('gender', GENDER.FEMALE)
                            else handleInput('gender', 0)
                        }}>
                            <p>여자</p>
                            <div className="login-stay-button" style={registerInfo.gender === GENDER.FEMALE ? RADIO_BUTTON_STYLE.SELECTED : RADIO_BUTTON_STYLE.DEFAULT}>
                                {
                                    registerInfo.gender === GENDER.FEMALE ? <CheckMarkIcon /> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="settings-info-item">
                        <p style={{ width: '15%', }}>생년월일</p>
                        <span>
                            <select class="register-date-input" title="year" onChange={(e) => { handleInput(e.target.title, e.target.value) }}>
                                <option disabled selected>출생 연도</option>
                                {
                                    [...Array.from({ length: (MAX_YEAR - MIN_YEAR + 1) }, (_, i) => MAX_YEAR - i)].map((v) => {
                                        return (
                                            <option id={v} value={v}>{v}</option>
                                        )
                                    })
                                }
                            </select>
                            <select class="register-date-input" title="month" onChange={(e) => { handleInput(e.target.title, e.target.value) }}>
                                <option disabled selected>월</option>
                                {
                                    [...Array.from({ length: 12 }, (_, i) => i + 1)].map((v) => {
                                        return (
                                            <option id={v} value={v}>{v}</option>
                                        )
                                    })
                                }
                            </select>
                            <select class="register-date-input" title="day" onChange={(e) => { handleInput(e.target.title, e.target.value) }}>
                                <option disabled selected>일</option>
                                {
                                    [...Array.from({ length: new Date(registerInfo.year, registerInfo.month, 0).getDate() }, (_, i) => i + 1)].map((v) => {
                                        return (
                                            <option id={v} value={v}>{v}</option>
                                        )
                                    })
                                }
                            </select>
                        </span>
                    </div>
                    <div className="settings-info-item">
                        <p style={{ width: '15%', }}>알림 수신</p>
                        <p>홍길동</p>
                    </div>
                </div> :
                    <div className="settings-main">
                        <h3 className="settings-title">기본 정보</h3>
                        <div className="settings-info-item">
                            <p>필명</p>
                            <p>홍길동</p>
                        </div>
                        <div className="settings-info-item">
                            <p>아이디</p>
                            <p>abcd</p>
                        </div>
                        <div className="settings-info-item">
                            <p>전화번호</p>
                            <p>010-0000-0000</p>
                        </div>
                        <div className="settings-info-item">
                            <p>비밀번호</p>
                            <p>-</p>
                        </div>
                        <div className="settings-info-item">
                            <p>성별</p>
                            <p>여</p>
                        </div>
                        <div className="settings-info-item">
                            <p>생년월일</p>
                            <p>1900.01.01</p>
                        </div>
                        <div className="settings-info-item">
                            <p>알림 수신</p>
                            <p></p>
                        </div>
                    </div>
            }


            {
                isEdit ? <div className="settings-policy">
                    <h3 className="settings-title">개인정보 보호 및 약관</h3>
                    <div className="settings-policy-item">
                        <p>[이용약관]<p className="settings-policy-more">자세히 보기</p></p>
                        <div className="settings-policy-check">
                            <div className="login-stay">
                                <p>동의함</p>
                                <div className="login-stay-button" style={RADIO_BUTTON_STYLE.SELECTED}>
                                    {
                                        <CheckMarkIcon />
                                    }
                                </div>
                            </div>
                            <div className="login-stay">
                                <p>동의하지 않음</p>
                                <div className="login-stay-button" style={RADIO_BUTTON_STYLE.DEFAULT}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-policy-item">
                        <p>[개인정보처리방침]<p className="settings-policy-more">자세히 보기</p></p>
                        <div className="settings-policy-check">
                            <div className="login-stay">
                                <p>동의함</p>
                                <div className="login-stay-button" style={RADIO_BUTTON_STYLE.SELECTED}>
                                    {
                                        <CheckMarkIcon />
                                    }
                                </div>
                            </div>
                            <div className="login-stay">
                                <p>동의하지 않음</p>
                                <div className="login-stay-button" style={RADIO_BUTTON_STYLE.DEFAULT}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            {
                isEdit ? <button id="settings-save-button" onClick={() => handleSave()}>저장하기</button> : <button id="settings-edit-button" onClick={() => {
                    window.location.href = `${process.env.REACT_APP_LIFERARY_CLIENT_URL}/dashboard/settings?edit=true`
                }}>수정하기</button>
            }
        </div>
    )
}