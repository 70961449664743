import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from '../icons/search.svg';
import { MODAL_MODE } from './ValueMapHome.jsx'

const typeInfo = [{
    text: '나를     형성하는     가치',
    color: 'rgb(136, 169, 162)',
}, {
    text: '내가     중시하는    가치',
    color: 'rgb(134, 142, 196)',
}, {
    text: '내가     외면하는     가치',
    color: 'rgb(196, 163, 180)',
}]

const AddValueModal = () => {

    const [selectedType, setSelectedType] = useState(new Set())

    return (
        <>
            <h3 id="valuemap-modal-title">태그 추가하기</h3>
            <div id="valuemap-modal-add-value-type">
                <div className="valuemap-modal-upper-content">
                    <h3>유형</h3>
                    <p>그 가치가 나에게 가지는 의미는 무엇인가요?</p>
                </div>
                <div id="add-value-type-select" className="noselect">
                    {
                        [...typeInfo].map((v, i) => {
                            return (
                                <span onClick={() => {
                                    if (selectedType.has(i)) {
                                        setSelectedType(prevType => new Set([...prevType].filter(x => x !== i)))
                                    } else {
                                        setSelectedType(prevType => new Set(prevType.add(i)))
                                    }
                                }
                                } style={{ background: selectedType.has(i) ? v.color : 'rgb(82, 82, 90)', color: selectedType.has(i) ? 'rgb(250, 250, 250)' : 'rgba(212, 212, 216, 1)' }}>{v.text}</span>
                            )
                        })
                    }
                    <p>*복수 선택 가능</p>
                </div>
            </div>
            <div id="valuemap-modal-add-values">
                <div className="valuemap-modal-upper-content">
                    <h3>가치</h3>
                    <p>나를 설명하는 가치는 무엇인가요?</p>
                </div>
                <div id="add-values-search-content">
                    <input type="text" placeholder="가치를 검색해주세요" maxLength="8" /><SearchIcon />
                    <div id="add-values-search-result">

                    </div>
                </div>
            </div>

            <div className="valuemap-modal-lower-buttons">
                <button className="modal-lower-button-save">저장하기</button>
                <button className="modal-lower-button-save-and-write">저장하고 글 작성하기</button>
            </div>
        </>
    )
}

const ValueTypeModal = (props) => {

    return (
        <>
            <h3 id="valuemap-modal-title">{typeInfo[props.type].text}</h3>
            <div id="valuemap-modal-add-value-type">
                <div className="valuemap-modal-upper-content">
                    <h3>가치</h3>
                    <p>나를 형성하는 가치는 무엇인가요?</p>
                </div>
            </div>
            <div className="valuemap-modal-lower-buttons">
                <button className="modal-lower-button-save">추가하기</button>
                <button className="modal-lower-button-save-and-write">글 작성하기</button>
            </div>
        </>
    )
}

const ShowValueModal = (props) => {
    console.log(props.value);

    return (
        <>
            <h3 id="valuemap-modal-title">{`# ${props.value.name}`}</h3>
        </>
    )
}

const ValueMapModal = (props) => {
    // props.mode : 모드대로 서로 다른 UI를 보여 주게끔 함.
    useEffect(() => {
        let modal = document.getElementById('valuemap-modal')
        if (modal) {
            modal.focus();
        }
    }, [])


    return (
        <div id="valuemap-modal" >
            {
                props.mode === MODAL_MODE.VALUEMAP_ADD_VALUE ? <AddValueModal /> : null
            }
            {
                props.mode >= MODAL_MODE.VALUEMAP_TYPE0 && props.mode <= MODAL_MODE.VALUEMAP_TYPE2 ? <ValueTypeModal type={props.mode - MODAL_MODE.VALUEMAP_TYPE0} /> : null
            }
            {
                props.mode === MODAL_MODE.VALUEMAP_SHOWVALUE ? <ShowValueModal value={props.modalValue} /> : null
            }
        </div>
    )
}

export default ValueMapModal;