import React, { useState, useEffect } from 'react';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import { ReactComponent as DashboardIcon } from '../icons/dashboard.svg';
import { ReactComponent as ValueMapIcon } from '../icons/valuemap.svg';
import { ReactComponent as DocumentIcon } from '../icons/document.svg';
import { ReactComponent as FeedIcon } from '../icons/feed.svg';
import { ReactComponent as ClipIcon } from '../icons/clip.svg';
import { ReactComponent as SettingIcon } from '../icons/settings.svg';
import { ReactComponent as CreditIcon } from '../icons/credit.svg';
import { ReactComponent as TrashcanIcon } from '../icons/trashcan.svg';
import { ReactComponent as ExitIcon } from '../icons/exit.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const menu = {
    DASHBOARD: 'home',
    VALUEMAP: 'map',
    DOCUMENT: 'document',
    FEED: 'feed',
    CLIP: 'clip',
    SETTINGS: 'settings',
    SUBSCRIPTION: 'subscription',
    TRASHCAN: 'removed',
    LOGOUT: 'logout',
}
Object.freeze(menu)

const SideItem = (props) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);

    const isChosen = () => {
        return (props.currentMenu === props.name);
    }

    return (
        <span className="side-item" onClick={() => {
            props.setCurrentMenu(props.name);
            if (props.link) navigate(props.link);

            if (props.onClick) props.onClick();
        }} style={{
            background: isChosen() ? 'linear-gradient(90deg, #9EFFF4 0%, #A0FFB5 100%)' : hover ? 'rgb(56, 56, 62)' : 'rgb(39, 39, 44)'
        }}
            onMouseOver={() => { setHover(true); }} onMouseLeave={() => { setHover(false); }}>
            <props.icon fill={!props.isStroke ? isChosen() ? 'rgb(24, 24, 27)' : 'rgba(161, 161, 169, 1)' : 'none'} stroke={props.isStroke ? isChosen() ? 'rgb(24, 24, 27)' : 'rgba(161, 161, 169, 1)' : 'none'} />
            <p className="side-item-description" style={{ width: props.isOpened ? '75%' : '0%', opacity: props.isOpened ? '1' : '0', color: isChosen() ? 'rgb(24, 24, 27)' : 'rgba(161, 161, 169, 1)', fontWeight: isChosen() ? '600' : '500' }}>{props.text}</p>
        </span >
    )
}

const SideNavBar = (props) => {
    const location = useLocation()
    const [isOpened, setIsOpened] = useState(false)
    const [isClickOpened, setIsClickOpened] = useState(false)
    const [currentMenu, setCurrentMenu] = useState('');
    const navBarStyle = isOpened ? { width: '29vmin' } : { width: '10.4vmin' }

    let timer;
    const handleMouseEnter = () => {
        if (!isClickOpened) {
            timer = setTimeout(() => {
                setIsOpened(true)
            }, 600);
        }
    }

    const handleMouseLeave = () => {
        if (!isClickOpened) {
            setIsOpened(false);
            clearTimeout(timer);
        }
    }

    const setItemByURL = () => {
        let urls = new URL(window.location.href).pathname.split('/')
        setCurrentMenu(urls[urls.length - 1]);
    }

    useEffect(() => {
        // execute on location change
        console.log('Location changed!', location.pathname);
        setItemByURL()
    }, [location]);

    let flag = false;
    useEffect(() => {
        if (!flag) {
            flag = true;
            setItemByURL()
        }
    }, []);

    return (
        <nav id="side-navbar" className="noselect" style={navBarStyle} onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
            <div id="side-navbar-icons">
                <div id="side-menu-icon">
                    <MenuIcon onClick={() => {
                        const io = isOpened;
                        setIsOpened(!io);
                        setIsClickOpened(!io);
                    }} />
                </div>

                <div id="side-my-icon">
                    <p className="side-content-description" style={{ opacity: isOpened ? '1' : '0' }}>나만의 공간</p>
                    <SideItem name={menu.DASHBOARD} icon={DashboardIcon} text={'대시보드'} link={'/dashboard/home'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                    <SideItem name={menu.VALUEMAP} icon={ValueMapIcon} text={'가치맵'} link={'/dashboard/map'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                    <SideItem name={menu.DOCUMENT} icon={DocumentIcon} text={'전체글'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                </div>
                <div id="side-inspire-icon">
                    <p className="side-content-description" style={{ width: isOpened ? '70%' : '0%', opacity: isOpened ? '1' : '0' }}>영감 얻어가기</p>
                    <SideItem name={menu.FEED} icon={FeedIcon} text={'피드'} link={'/dashboard/feed'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                    <SideItem name={menu.CLIP} icon={ClipIcon} text={'스크랩'} link={'/dashboard/clip'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                </div>

                <div id="side-setting-icon">
                    <SideItem name={menu.SETTINGS} icon={SettingIcon} link="/dashboard/settings" text={'설정'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                    <SideItem name={menu.SUBSCRIPTION} icon={CreditIcon} link="/dashboard/subscription" text={'구독 & 크레딧'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                    <SideItem name={menu.TRASHCAN} icon={TrashcanIcon} link={'/dashboard/removed'} text={'휴지통'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                    <SideItem name={menu.LOGOUT} icon={ExitIcon} text={'로그아웃'} isOpened={isOpened} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu}
                        onClick={() => { props.setIsLogined(false); window.location.reload() }} />
                </div>
            </div>
            <div id="side-navbar-content">

            </div>
        </nav>
    )
}

export default SideNavBar;