import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/logo.png';

import { ReactComponent as NotificationIcon } from '../icons/notification.svg';
import { ReactComponent as NewNotificationIcon } from '../icons/notification_new.svg';
import { ReactComponent as CreditIcon } from '../icons/credit.svg';

const NotificationContent = () => {
    const [notificationText, setnotificationText] = useState([
        {
            text: '오리지널 콘텐츠 <니체의 자아실현> 업로드',
            date: '2024.08.15',
        },
        {
            text: '⭐️ 이번주 가장 빛난 글 7선에 선정되었어요!',
            date: '2024.08.16',
        },
        {
            text: '오리지널 콘텐츠 <니체의 자아실현> 업로드',
            date: '2024.08.17',
        }])

    return (
        <div className="notification-content">
            {
                notificationText.map((v, i) => {
                    return (
                        <>
                            <div className="dashboard-notification-content">
                                <p>{v.text}</p>
                                <p style={{ display: 'none', }}>{v.date}</p>
                            </div>
                            {
                                i < notificationText.length - 1 ? <div className="dashboard-line-division" /> : null
                            }
                        </>
                    )
                })
            }
        </div>
    )
}

const NavigationBar = (props) => {
    const navigate = useNavigate();
    const [notificationVisible, setNotificationVisible] = useState(false)

    return (
        <nav className="navigation-bar noselect">
            <div className="logo-section">
                <img src={Logo} alt="navigation-logo" style={{ cursor: 'pointer', }} onClick={() => {
                    props.isLogined ? navigate('/dashboard/home') : navigate('/')
                }} />
            </div>
            {
                !props.isLogined ? <div className="menu-section">
                    <button className="menu-item" onClick={() => { navigate('/') }}>홈</button>
                    <button className="menu-item">팀 소개</button>
                    <button className="menu-item">구독플랜</button>
                </div> : null
            }

            <div className="button-section">
                {
                    props.isLogined ? <>
                        <NotificationIcon fill="rgb(161, 161, 169)" onClick={() => { setNotificationVisible(!notificationVisible) }} />
                        {
                            notificationVisible ? <NotificationContent /> : null
                        }

                        <CreditIcon fill="rgba(161, 161, 169, 1)" />
                        <p>51</p>
                        <button className="write-button">글쓰기</button>
                    </> :
                        <>
                            <button className="login-button" onClick={() => navigate('/login')}>로그인</button>
                            {/* <button className="start-button">시작하기</button> */}
                        </>
                }

            </div>
        </nav>
    )
}

export default NavigationBar;