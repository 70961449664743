import React, { useEffect, useState } from 'react';

import ValueMap from './ValueMap.jsx'
import ValueMapModal from './ValueMapModal.jsx'

import { ReactComponent as QuestionMarkIcon } from '../icons/question-mark.svg';
import { ReactComponent as ExclamationMark } from '../icons/exclamation-mark.svg';
import { ReactComponent as ValueMapIcon } from '../icons/valuemap-switch.svg';
import { ReactComponent as BranchIcon } from '../icons/branch.svg';
import { ReactComponent as ValueAddIcon } from '../icons/add.svg';

const POPUP_MODE = {
    info: 0,
    deleted: 1,
    generated: 2,
}
const valueMapMode = {
    VALUEMAP: false,
    BRANCH: true,
}
const MODAL_MODE = {
    VALUEMAP_ADD_VALUE: 0,
    VALUEMAP_EDIT_VALUE: 1,
    VALUEMAP_TYPE0: 2,
    VALUEMAP_TYPE1: 3,
    VALUEMAP_TYPE2: 4,
    VALUEMAP_SHOWVALUE: 5,
}
Object.freeze(POPUP_MODE, valueMapMode, MODAL_MODE)

const ValueMapPopup = (props) => {
    const btnTexts = ['확인', '되돌리기'];
    const [displayPopup, setDisplayPopup] = useState(1);

    const handleClick = () => {
        setDisplayPopup(0);
    }

    const btnStyle = {
        weak: {
            background: 'rgba(110, 247, 222, 0.4)',
            color: 'rgba(244, 244, 245, 1)',
        },
        strong: {
            background: 'linear-gradient(90deg, #9EFFF4 0%, #A0FFB5 100%)',
            color: 'rgba(39, 39, 42, 1)'
        }
    }

    return (
        <div className="valuemap-popup" style={{ opacity: displayPopup, background: props.mode === POPUP_MODE.info ? 'rgba(110, 247, 222, 0.15)' : 'linear-gradient(90deg, rgba(120, 188, 181, 0.9) 0%, rgba(121, 188, 137, 0.9) 100%)' }}>
            {
                props.mode === POPUP_MODE.info ? <>
                    <div className="valuemap-popup-content">
                        <QuestionMarkIcon fill="rgba(212, 212, 216, 1)" />
                        <p>드래그하여 태그를 움직여 보세요!</p>
                    </div>
                    <button className="valuemap-popup-button" onClick={() => handleClick()}
                        style={btnStyle.weak}>{btnTexts[props.mode]}</button>
                </> : null
            }

            {
                props.mode === POPUP_MODE.deleted ? <>
                    <div className="valuemap-popup-content">
                        <ExclamationMark />
                        <p style={{ color: 'rgba(250, 250, 250, 1)' }}>{`#${'태그'} 태그가 삭제되었습니다.
관련 글은 휴지통에 3개월간 보관됩니다.`}</p>
                    </div>
                    <button className="valuemap-popup-button" onClick={() => handleClick()} style={btnStyle.strong}>{btnTexts[props.mode]}</button>
                </> : null
            }

            {
                props.mode === POPUP_MODE.generated ? <>
                    <div className="valuemap-popup-content">
                        <ExclamationMark />
                        <p style={{ color: 'rgba(250, 250, 250, 1)' }}>{`#${'태그'} 생성이 완료되었습니다.`}</p>
                    </div>
                </> : null
            }
        </div>
    )
}

const ValueMapControl = (props) => {
    const [valueMapState, setValueMapState] = useState(valueMapMode.VALUEMAP);


    return (
        <div className="value-map-toggle-content">
            <button className="value-map-add-button" onClick={() => { props.setShowModal(true); props.setModalMode(MODAL_MODE.VALUEMAP_ADD_VALUE) }}>
                <ValueAddIcon />
            </button>
            <label className="value-map-toggle-switch">
                <span className="value-map-toggle-icon">
                    <ValueMapIcon fill={valueMapState === valueMapMode.VALUEMAP ? 'rgba(63, 63, 70, 1)' : 'rgba(212, 212, 216, 1)'} />
                </span>
                <span className="value-map-toggle-icon">
                    <BranchIcon fill={valueMapState === valueMapMode.BRANCH ? 'rgba(63, 63, 70, 1)' : 'rgba(212, 212, 216, 1)'} />
                </span>
                <input type="checkbox" id="value-map-toggle-input" checked={valueMapState} onClick={() => { setValueMapState(!valueMapState) }} />
                <span className="value-map-toggle-button"></span>
            </label>
        </div >
    )
}

const ValueMapHome = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState(0);
    const [modalValue, setModalValue] = useState();

    return (
        <div className="valuemap-home-container" >
            <ValueMapPopup mode={POPUP_MODE.deleted} />
            <ValueMapControl setShowModal={setShowModal} setModalMode={setModalMode} />
            <ValueMap viewOnly={false}
                modalMode={modalMode} setModalMode={setModalMode} showModal={showModal} setShowModal={setShowModal} modalValue={modalValue} setModalValue={setModalValue} />
            {
                showModal ? <ValueMapModal setShowModal={setShowModal} mode={modalMode} modalValue={modalValue} /> : null
            }
        </div>
    )
}

export { ValueMapHome, MODAL_MODE };