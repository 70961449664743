import React, { useState, useEffect } from 'react';
import { MODAL_MODE } from './ValueMapHome'
import { useNavigate } from 'react-router-dom';

const circles = {
    c1: {
        x: 393,
        y: 260,
    },
    c2: {
        x: 268,
        y: 475,
    },
    c3: {
        x: 518,
        y: 475,
    },
    r: 204
}
Object.freeze(circles)

// 두 점 사이의 거리를 계산하는 함수
function calculateDistance(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
}



const ValueVertex = (props) => {
    const moveAble = props.moveable;
    const [color, setColor] = useState('')
    const [postPosition, setPostPosition] = useState({
        x: 100,
        y: 100,
    })

    const [radius] = useState(6);

    // 각 원 안에 있는지 판별하는 함수
    const isInsideC1 = () => {
        return (calculateDistance({ x: circles.c1.x, y: circles.c1.y }, { x: props.position.x + 40, y: props.position.y + 15 }) < circles.r) ? 1 : 0;
    }

    const isInsideC2 = () => {
        return (calculateDistance({ x: circles.c2.x, y: circles.c2.y }, { x: props.position.x + 40, y: props.position.y + 15 }) < circles.r) ? 1 : 0;
    }

    const isInsideC3 = () => {
        return (calculateDistance({ x: circles.c3.x, y: circles.c3.y }, { x: props.position.x + 40, y: props.position.y + 15 }) < circles.r) ? 1 : 0;
    }

    const changePos = (newPos) => {
        let newVertexes = props.vertexes.map((v, i) => {
            return (
                props.index === i ? { ...newPos } : { ...v, clicked: false }
            )
        })
        props.setVertexes(newVertexes);
        props.setEdges([])
    }

    const handlePointerDown = e => {
        const el = e.target;
        const bbox = e.target.getBoundingClientRect();
        const x = e.clientX - bbox.left;
        const y = e.clientY - bbox.top;
        el.setPointerCapture(e.pointerId);

        setPostPosition({
            x: props.position.x,
            y: props.position.y,
        });

        const newPos = {
            ...props.position,
            active: true,
            offset: {
                x,
                y
            }
        }
        changePos(newPos);
    };
    const handlePointerMove = e => {
        const bbox = e.target.getBoundingClientRect();
        const x = e.clientX - bbox.left;
        const y = e.clientY - bbox.top;
        if (props.position.active) {
            const newPos = {
                ...props.position,
                x: props.position.x - (props.position.offset.x - x),
                y: props.position.y - (props.position.offset.y - y),
                clicked: false,
            }

            changePos(newPos)
            setUnion()
        }
    };
    const handlePointerUp = e => {
        const newPos = {
            ...props.position,
            active: false
        }
        if (!(isInsideC1() || isInsideC2() || isInsideC3())) { // 원 밖이라면 이동을 무효로 한다.
            newPos.x = postPosition.x;
            newPos.y = postPosition.y;
        }
        changePos(newPos);
    };

    const handleClick = e => {
        if (moveAble) {
            if (postPosition.x === props.position.x && postPosition.y === props.position.y) {

                // makeSpanningTree();
                if (props.modalMode !== MODAL_MODE.VALUEMAP_SHOWVALUE) {
                    props.setModalMode(MODAL_MODE.VALUEMAP_SHOWVALUE)
                    props.setModalValue(props.position)
                    props.setShowModal(true)
                } else if (props.modalValue === props.position) {
                    props.setShowModal(!props.showModal)
                } else {
                    props.setModalValue(props.position)
                }

            } else {
                // props.setEdges([]);
            }
        }
    }

    const makeSpanningTree = () => {
        const vertices = props.vertexes.map((v) => ({ 'x': v.x, 'y': v.y }));

        function getAllEdges(vertices) {
            const edges = [];
            for (let i = 0; i < vertices.length; i++) {
                for (let j = i + 1; j < vertices.length; j++) {
                    edges.push({
                        start: vertices[i],
                        end: vertices[j],
                        distance: calculateDistance(vertices[i], vertices[j])
                    });
                }
            }
            return edges;
        }

        const edges = getAllEdges(vertices);



        // Prim's Algorithm을 이용하여 Minimum Spanning Tree를 찾는 함수
        function primMST(vertices, edges) {
            const mst = [];
            const visited = new Set();
            visited.add(vertices[0]);

            while (visited.size < vertices.length) {
                let minEdge = null;
                let minDistance = Infinity;

                for (const edge of edges) {
                    if ((visited.has(edge.start) && !visited.has(edge.end)) || (visited.has(edge.end) && !visited.has(edge.start))) {
                        const distance = calculateDistance(edge.start, edge.end);
                        if (distance < minDistance) {
                            minDistance = distance;
                            minEdge = edge;
                        }
                    }
                }

                if (minEdge) {
                    mst.push(minEdge);
                    visited.add(minEdge.start);
                    visited.add(minEdge.end);
                }
            }

            return mst;
        }

        // Minimum Spanning Tree를 찾고 결과를 출력합니다.
        const mst = primMST(vertices, edges);
        const result = mst.map(edge => ({ start: edge.start, end: edge.end }));
        props.setEdges(result);

        console.log(result);
    }

    const setUnion = () => {
        const COLORS = ['rgba(186, 186, 186, 0.8)', 'rgba(174, 219, 209, 0.8)', 'rgba(158, 167, 234, 0.8)', 'rgba(185, 212, 239, 0.8)', 'rgba(235, 194, 214, 0.8)', 'rgba(209, 209, 220, 0.8)', 'rgba(219, 203, 251, 0.8)', 'rgba(255, 255, 255, 0.8)']


        setColor(COLORS[isInsideC1() * 1 + isInsideC2() * 2 + isInsideC3() * 4]);
    }

    useEffect(() => {
        setUnion()
    }, [props.vertexes])

    return (
        <svg width="140" height="60" x={props.position.x} y={props.position.y}>
            <defs>
                <filter id="blur" x="-50%" y="-50%" width="200%" height="200%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
                </filter>
            </defs>
            <circle cx="70" cy="15" r={Math.floor(radius * 1.7)} fill={color} filter="url(#blur)" />
            <circle
                cx="70"
                cy="15"
                r={radius}
                onPointerDown={moveAble ? handlePointerDown : null}
                onPointerUp={moveAble ? handlePointerUp : null}
                onPointerMove={moveAble ? handlePointerMove : null}
                onClick={moveAble ? handleClick : null}
                fill={color}
                opacity="0.8"
            />
            <text x="70" y="45" fill='#eee' dominant-baseline="middle" text-anchor="middle"
                fontSize={props.vertexes[props.index].clicked ? '3vmin' : '2.3vmin'}>{`#${props.position.name}`}</text>
        </svg>
    );
}


const ValueMap = (props) => {
    const moveAble = !props.viewOnly;
    const [vertexes, setVertexes] = useState([])
    const [vertexNum, setVertexNum] = useState(7);
    const [edges, setEdges] = useState([])
    const navigate = useNavigate();
    let flag = false;

    const initialize = () => {
        let initVertexes = [];
        for (let i = 0; i < vertexNum; i++) {
            initVertexes.push({
                name: `가치${i + 1}`,
                x: Math.floor(Math.random() * 350) + 250,
                y: Math.floor(Math.random() * 350) + 250,
                // size : 1~2개 : 1단계, 3~4개 : 2단계, 5개> : 3단계
                // date
                active: false,
                clicked: false,
                offset: {}
            });
        }

        setVertexes(initVertexes);
        setEdges([])
    }

    const isActive = (mode) => {
        return props.modalMode === mode && props.showModal;
    }

    useEffect(() => {
        if (!flag) {
            flag = true;

            initialize();

        }
    }, [])

    return (
        <svg viewBox="0 0 770 770" width="100%" height="100%" preserveAspectRatio="xMidYMid meet" className="noselect" id="value-map-diagram"
            onClick={() => { // When clicked at Dashboard, move to 가치맵
                if (!moveAble) {
                    navigate('/dashboard/map')
                }
            }}
        >

            <circle
                cx={circles.c1.x}
                cy={circles.c1.y}
                r={circles.r}
                fill={isActive(MODAL_MODE.VALUEMAP_TYPE0) ? 'rgba(144, 203, 190, 0.5)' : "rgba(144, 203, 190, 0.2)"} stroke="rgba(144, 203, 190, 1)" strokeWidth="1px"
                onClick={() => {
                    if (props.modalMode && moveAble) {
                        if (props.modalMode != MODAL_MODE.VALUEMAP_TYPE0 + 0) {
                            props.setModalMode(MODAL_MODE.VALUEMAP_TYPE0 + 0);
                            props.setShowModal(true);
                        } else {
                            props.setShowModal(!props.showModal);
                        }
                    }
                }}
            />
            <rect x={295} y={50} width={190} height={40} fill={isActive(MODAL_MODE.VALUEMAP_TYPE0) ? "rgba(147, 201, 189, 1)" : "rgba(136, 169, 163, 1)"} rx={22} />
            <text x={390} y={70} fill={"rgba(250, 250, 250, 1)"} dominant-baseline="middle" text-anchor="middle" fontSize="17">나를 형성하는 가치</text>

            <circle
                cx={circles.c2.x}
                cy={circles.c2.y}
                r={circles.r}
                fill={isActive(MODAL_MODE.VALUEMAP_TYPE1) ? "rgba(154, 160, 206, 0.5)" : "rgba(154, 160, 206, 0.2)"} stroke="rgba(154, 160, 206, 1)" strokeWidth="1px"
                onClick={() => {
                    if (props.modalMode && moveAble) {
                        if (props.modalMode != MODAL_MODE.VALUEMAP_TYPE0 + 1) {
                            props.setModalMode(MODAL_MODE.VALUEMAP_TYPE0 + 1);
                            props.setShowModal(true);
                        } else {
                            props.setShowModal(!props.showModal);
                        }
                    }
                }}
            />
            <rect x={170} y={650} width={190} height={40} fill={isActive(MODAL_MODE.VALUEMAP_TYPE1) ? "rgba(171, 177, 223, 1)" : "rgba(134, 141, 196, 1)"} rx={22} />
            <text x={265} y={670} fill={"rgba(250, 250, 250, 1)"} dominant-baseline="middle" text-anchor="middle" fontSize="17">내가 중시하는 가치</text>

            <circle
                cx={circles.c3.x}
                cy={circles.c3.y}
                r={circles.r}
                fill={isActive(MODAL_MODE.VALUEMAP_TYPE2) ? "rgba(224, 192, 208, 0.5)" : "rgba(224, 192, 208, 0.2)"} stroke="rgba(224, 192, 208, 1)" strokeWidth="1px"
                onClick={() => {
                    if (props.modalMode && moveAble) {
                        if (props.modalMode != MODAL_MODE.VALUEMAP_TYPE0 + 2) {
                            props.setModalMode(MODAL_MODE.VALUEMAP_TYPE0 + 2);
                            props.setShowModal(true);
                        } else {
                            props.setShowModal(!props.showModal);
                        }
                    }
                }}
            />
            <rect x={425} y={650} width={190} height={40} fill={isActive(MODAL_MODE.VALUEMAP_TYPE2) ? "rgba(224, 191, 209, 1)" : "rgba(195, 163, 180, 1)"} rx={22} />
            <text x={520} y={670} fill="rgba(250, 250, 250, 1)" dominant-baseline="middle" text-anchor="middle" fontSize="17">내가 외면하는 가치</text>

            {
                vertexes.map((v, i) => {
                    return (
                        <ValueVertex vertexes={vertexes} setVertexes={setVertexes} setEdges={setEdges} position={v} index={i}
                            moveable={moveAble} modalMode={props.modalMode} setModalMode={props.setModalMode} showModal={props.showModal} setShowModal={props.setShowModal} modalValue={props.modalValue} setModalValue={props.setModalValue} />
                    )
                })
            }
        </svg>
    )
}

export default ValueMap;