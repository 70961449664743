import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useNaverQuery } from '../apis/naver.js'

import { ReactComponent as CheckMarkIcon } from '../icons/checkmark.svg';

import SoclaiGoogleIcon from '../images/social_google.png';
import SoclaiNaverIcon from '../images/social_naver.png';
import SoclaiKakaoIcon from '../images/social_kakao.png';

function escapeHtml(text) {
    return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

const LoginScreen = () => {
    const navigate = useNavigate();

    const [loginInfo, setLoginInfo] = useState({
        id: '',
        password: '',
    })

    const MAX_TRY_COUNT = 5;
    const ERROR_MESSAGES = {
        BLANK_ID: "아이디를 입력해 주세요.",
        BLANK_PW: "비밀번호를 입력해 주세요.",
        WRONG_ID_OR_PW: "아이디 또는 비밀번호가 일치하지 않습니다. 다시 시도해 주세요.",
        TOO_MUCH_TRIES: `아이디 또는 비밀번호가 일치하지 않습니다. 다시 시도해 주세요.
${MAX_TRY_COUNT}회 연속으로 시도하셨습니다. 잠시 후 다시 시도해 주세요.`,
    }
    const [errorMsg, setErrorMsg] = useState('')
    async function isValidToken() {

    }

    let flag = false;
    useEffect(() => {
        if (!flag) {
            flag = true;
            // isValidToken()
        }
    }, [])


    const onChange = (e) => {
        setLoginInfo({
            ...loginInfo,
            [e.target.name]: e.target.value,
        });
    }


    const handleRegisterClick = () => {
        // 아이디 입력 X
        if (!loginInfo.id) setErrorMsg(ERROR_MESSAGES.BLANK_ID);
        else if (!loginInfo.password) setErrorMsg(ERROR_MESSAGES.BLANK_PW);

        // axios.post('https://www.liferaryback.xyz/user/login', loginInfo)
        //     .then((res) => {
        //         //console.log(res)

        //         const token = res.data.token;
        //         window.localStorage.setItem('token', token);
        //         navigate('/main')
        //     })
        //     .catch((error) => {
        //         alert(error)
        //     })
    };

    const LoginOnKakao = () => {
        console.log('카카오 로그인을 시도합니다.')

        // const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`

        const KAKAO_AUTH_URL = `${process.env.REACT_APP_LIFERARY_SERVER_URL}/user/kakao`
        window.location.href = KAKAO_AUTH_URL
    }

    const LoginOnNaver = async () => {
        console.log('네이버 로그인을 시도합니다.')

        const params = new URLSearchParams({
            client_id: process.env.REACT_APP_NAVER_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_NAVER_REDIRECT_URI,
            state: Math.random().toString(36).substring(3, 14),
            response_type: 'code',
        }).toString()

        window.location.href = `https://nid.naver.com/oauth2.0/authorize?${params}`
    }

    const [doLoginStay, setDoLoginStay] = useState(false);

    return (
        <div className="login-screen noselect">
            <div id="login-content">
                <div id="login-desc">
                    <h1>로그인</h1>
                    <p className="login-error-message">{errorMsg}</p>
                </div>
                <div className="login-input-section">
                    <input name="id" type="text" placeholder="아이디를 입력해주세요" onChange={onChange} />
                </div>
                <div className="login-input-section">
                    <input name="password" type="password" placeholder="비밀번호를 입력해주세요" onChange={onChange} />
                </div>
                <div className="login-setting-section">
                    <div className="login-stay" onClick={() => { setDoLoginStay(!doLoginStay) }}>
                        <p>로그인 유지</p>
                        <div className="login-stay-button" style={{
                            background: doLoginStay ? 'rgba(110, 247, 222, 1)' : 'none',
                            border: doLoginStay ? '1px solid' : '1px solid rgba(82, 82, 90, 1)',
                        }}>
                            {
                                doLoginStay ? <CheckMarkIcon /> : null
                            }
                        </div>
                    </div>

                    <div className="login-find">
                        <button>아이디 찾기</button>
                        <span className="login-find-partition" />
                        <button>비밀번호 찾기</button>
                    </div>
                </div>

                <button id="login-button" onClick={handleRegisterClick}>로그인</button>
                <div className="login-social-section">
                    <div>
                        <span />
                        <p>간편하게 시작하기</p>
                    </div>
                    <div className="login-social-icons">
                        <img src={SoclaiNaverIcon} onClick={() => { LoginOnNaver() }} />
                        <img src={SoclaiKakaoIcon} onClick={() => { LoginOnKakao() }} />
                        <img src={SoclaiGoogleIcon} />
                    </div>
                </div>
                <div className="login-register-section">
                    <p>처음이신가요?</p>
                    <a id="to-register" href="/register">가입하기</a>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;